import React, { useState, useEffect, useRef, useContext } from "react";
import { Input } from "baseui/input";
import { StatefulPopover } from "baseui/popover";
import "./nested.css";
import DownArrow from "../../utils/DownArrow";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { useForm, Controller } from "react-hook-form";
import { FormControl } from "baseui/form-control";
import { useSelector } from "react-redux";
import ViewList from "./ViewList";
import { TextBox } from "../TextBox";
import { TextButton } from "../TextButton";
import { Label1 } from "baseui/typography";
import { FormattedMessage, useIntl } from "react-intl";
import CheckBox from "../../components/CheckBoxBaseweb";
import { useMutation, useQuery } from "react-query";
import { createView, getGridStructure } from "../../services";
import { useRBAC, permission } from "../../providers/RBACProvider";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import moment from "moment";
import getSelectValues from "../../utils/getSelectValues";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  ADD_VIEW,
  ADD_VIEWS,
} from "../../providers/RBACProvider/permissionList";

import TripleDot from "../../assets/img/svg/TripleDot";
import { Button, SIZE, KIND } from "baseui/button";
import { useDispatch } from "react-redux";
import { logDOM } from "@testing-library/react";
import { Icon } from "../../components_v2";
import { CUSTOMER_OVERVIEW } from "../../constants";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import {
  customerData,
  getCustomerCampaign,
} from "../../services/customerSummary";
import { StatefulTooltip } from "baseui/tooltip";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import Loader from "../Loader";

const InnerComp = ({
  close,
  menu,
  tableDraftView,
  setView,
  gridStructure,
  currentFilteringData,
  colOrder,
  tableCurrentState,
  refetchPinnedViews,
  pinnedViewsState,
  pinnedView,
}) => {
  const intl = useIntl();

  const [state, setState] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      is_org: false,
      is_default: false,
    },
  });

  let { currentOrganization } = useContext(MaxyfiContext);

  const createViewMutation = useMutation(
    (viewData) =>
      createView({ organization: currentOrganization, ...viewData }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        close();
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {
      ...data,
      menu: menu,
      table: tableDraftView.table,
      filter: tableCurrentState.filters,
      customer_stage: tableCurrentState.customer_stage,
      needs_action: tableCurrentState.needs_action,
    };

    await createViewMutation.mutateAsync(values);
  };

  return (
    <div className="view-picker">
      {state ? (
        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "10px" }}>
          <div className="view-picker__title">
            <Label1>
              <FormattedMessage id="add_view">add_view</FormattedMessage>
            </Label1>
          </div>

          <Controller
            control={control}
            rules={{
              required: intl.formatMessage({
                id: "required",
              }),
            }}
            render={({ field }) => (
              <TextBox
                {...field}
                name={field.name}
                error={errors.name && errors.name.message}
                label={intl.formatMessage({
                  id: "view_name",
                })}
                placeholder={intl.formatMessage({
                  id: "name",
                })}
                value={field.value}
              />
            )}
            name="name"
          />

          <Controller
            control={control}
            rules={{
              required: intl.formatMessage({
                id: "required",
              }),
            }}
            render={({ field }) => (
              <TextBox
                {...field}
                name={field.name}
                error={errors.description && errors.description.message}
                label={intl.formatMessage({
                  id: "view_description",
                })}
                placeholder={intl.formatMessage({
                  id: "description",
                })}
                value={field.value}
              />
            )}
            name="description"
          />

          <Controller
            name="is_org"
            control={control}
            render={({ field }) => (
              <FormControl>
                <CheckBox
                  {...field}
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <FormattedMessage id="enable_for_organization_users">
                    enable_for_organization_users
                  </FormattedMessage>
                </CheckBox>
              </FormControl>
            )}
          />

          <Controller
            name="is_default"
            control={control}
            render={({ field }) => (
              <FormControl>
                <CheckBox
                  {...field}
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <FormattedMessage id="default_view">
                    default_view
                  </FormattedMessage>
                </CheckBox>
              </FormControl>
            )}
          />
          <Controller
            name="pinned_users"
            control={control}
            render={({ field }) => (
              <FormControl>
                <CheckBox
                  {...field}
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <FormattedMessage id="pinned_view">
                    pinned_view
                  </FormattedMessage>
                </CheckBox>
              </FormControl>
            )}
          />
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <TextButton
                kind={KIND.tertiary}
                size={SIZE.compact}
                onClick={() => {
                  setState(false);
                }}
                style={{ color: "#516BEB" }}
              >
                <FormattedMessage id="cancel">cancel</FormattedMessage>
              </TextButton>
            </div>
            <div>
              <TextButton
                size={SIZE.compact}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {" + "}
                <FormattedMessage id="create_view">
                  create_view
                </FormattedMessage>
              </TextButton>
            </div>
          </div>
        </form>
      ) : (
        <>
          <ViewList
            menu={menu}
            setView={setView}
            colOrder={colOrder}
            refetchPinnedViews={refetchPinnedViews}
            pinnedViewsState={pinnedViewsState}
            pinnedView={pinnedView}
          />
          <RBACWrapper role={ADD_VIEWS} type={"PERMISSION"}>
            <div
              className="view-picker__save-view-btn"
              onClick={() => {
                setState(true);
              }}
              disabled={tableDraftView.table.length === 0}
            >
              {/* <TextButton
                  onClick={() => {
                    setState(true);
                  }}
                  size={SIZE.compact}
                  fullWidth={true}
                  disabled={tableDraftView.table.length === 0}
                > */}
              {"+ "}
              <FormattedMessage id="add_view">add_view</FormattedMessage>
              {/* </TextButton> */}
            </div>
          </RBACWrapper>
        </>
      )}
    </div>
  );
};

const TableViewPicker = ({
  tableDraftView,
  menu,
  viewName,
  setView,
  currentFilteringData,
  gridStructure,
  overAllFilters,
  colOrder,
  tableCurrentState,
  pinnedView = [],
  setPinnedView,
  ...props
}) => {
  const intl = useIntl();
  let { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pinnedViewsState, setPinnedViewsState] = useState(false);

  const refetchPinnedViews = async () => {
    await getGridStructure({
      organization: currentOrganization,
      menu,
    }).then(async (gridStructureRes) => {
      if (
        gridStructureRes &&
        gridStructureRes.data &&
        gridStructureRes.data.doc &&
        gridStructureRes.data.doc.pinned_view &&
        Array.isArray(gridStructureRes.data.doc.pinned_view) &&
        setPinnedView
      ) {
        dispatch(setPinnedView(gridStructureRes.data.doc.pinned_view));
      }
    });
  };

  const filterPinnedTable = pinnedView.find((item) => item.name === viewName);
  //
  let tablerOrderDefault = useSelector(
    (state) => state?.customerSummary?.tableOrder
  );

  const {
    mutateAsync: fetchCustomerCampaign,
    isLoading: isCustomerCampaignLoading,
  } = useMutation(
    async (variables) => {
      const { pageIndex, sortBy, pageSize, filters, customerFilter } =
        variables;

      const newFilter = dynamicQueryFilter({
        gridStructure: gridStructure,
        timeZone: currentDefaultFormatDetails.time_zone,
        customerFilter: customerFilter,
      });

      return await getCustomerCampaign({
        organization: currentOrganization,
        pageIndex,
        sortBy,
        pageSize,
        filters,
        pg_type: "R",
        customerFilter: newFilter,
      });
    },
    {
      onError: (error) => {
        console.error("Error fetching customer campaign:", error);
        toast.error("An error occurred while fetching the data.");
      },
      onSuccess: (response) => {
        if (_.get(response, "data.doc._id", "")) {
          navigate(`/customers/${_.get(response, "data.doc._id", "")}`); // Redirect to new route
        } else if (_.get(response, "data.message", "")) {
          toast.success(_.get(response, "data.message", ""));
        }
      },
    }
  );

  return (
    <div className="view-wrapper">
      {/* {tablerOrderDefault !== undefined && !isActiveCheck ? (
        <StatefulTooltip
          accessibilityType={"tooltip"}
          content={
            <div style={{ minWidth: "auto", maxWidth: "200px" }}>
              Click Next to work Queue
            </div>
          }
        >
          <div>
            {isCustomerCampaignLoading ? (
              <Loader size={10} />
            ) : (
              <Icon
                icon="next_start"
                color="var(--primary)"
                isPressable
                size={25}
                onClick={async () => {
                  await fetchCustomerCampaign({
                    pageIndex: tableCurrentState.pageIndex + 1,
                    ...(currentDefaultFormatDetails.en_sort
                      ? {
                          sortBy:
                            tableCurrentState.sort && tableCurrentState.sort[0]
                              ? tableCurrentState.sort[0].desc
                                ? "-" + tableCurrentState.sort[0].id
                                : tableCurrentState.sort[0].id
                              : "",
                        }
                      : {}),
                    ...overAllFilters,
                  });
                }}
              />
            )}
          </div>
        </StatefulTooltip>
      ) : (
        <></>
      )} */}
      {pinnedView && Array.isArray(pinnedView) ? (
        pinnedView.map((e, i) => {
          if (!e._id || !e.table || !Array.isArray(e.table) || i >= 4) {
            return <></>;
          }
          const allActiveCheckTrue = pinnedView.every(
            (e, i) => viewName !== e.name
          );

          const isActiveCheck = viewName !== e.name;
          let { table, ...rest } = props;
          console.log(allActiveCheckTrue, "isActiveCheck");
          return (
            <>
              {allActiveCheckTrue && i === 0 ? ( // Show StatefulTooltip for the first matching condition
                <StatefulTooltip
                  accessibilityType={"tooltip"}
                  content={
                    <div style={{ minWidth: "auto", maxWidth: "200px" }}>
                      Click Next to work Queue
                    </div>
                  }
                >
                  <div>
                    {isCustomerCampaignLoading ? (
                      <Loader size={10} />
                    ) : (
                      <Icon
                        icon="next_start"
                        color="var(--primary)"
                        isPressable
                        size={25}
                        onClick={async () => {
                          await fetchCustomerCampaign({
                            pageIndex: tableCurrentState.pageIndex + 1,
                            ...(currentDefaultFormatDetails.en_sort
                              ? {
                                  sortBy:
                                    tableCurrentState.sort &&
                                    tableCurrentState.sort[0]
                                      ? tableCurrentState.sort[0].desc
                                        ? "-" + tableCurrentState.sort[0].id
                                        : tableCurrentState.sort[0].id
                                      : "",
                                }
                              : {}),
                            ...overAllFilters,
                          });
                        }}
                      />
                    )}
                  </div>
                </StatefulTooltip>
              ) : null}
              <div
                className={`view-pinned ${
                  viewName === e.name ? "view-pinned--selected" : ""
                }`}
                onClick={async () => {
                  if (viewName === e.name) {
                    await fetchCustomerCampaign({
                      pageIndex: tableCurrentState.pageIndex + 1,
                      ...(currentDefaultFormatDetails.en_sort
                        ? {
                            sortBy:
                              tableCurrentState.sort &&
                              tableCurrentState.sort[0]
                                ? tableCurrentState.sort[0].desc
                                  ? "-" + tableCurrentState.sort[0].id
                                  : tableCurrentState.sort[0].id
                                : "",
                          }
                        : {}),
                      ...overAllFilters,
                    });
                  } else {
                    setView({ menu, ...e });

                    colOrder(
                      table && table.map((i) => ["selection", ...i.col_id])
                    );
                  }
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "2px" }}
                >
                  <p className="view-pinned__name">{e.name}</p>
                  {viewName === e.name ? (
                    isCustomerCampaignLoading ? (
                      <Loader size={10} />
                    ) : (
                      <Icon
                        className="view-pinned__name"
                        icon="next_start"
                        color="var(--primary)"
                        isPressable
                        size={15.5}
                      />
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          );
        })
      ) : (
        <></>
      )}

      <StatefulPopover
        overrides={{
          Body: {
            style: ({ $theme }) => ({
              borderRadius: "5px",
              // overflow: "hidden",
              width: "273px",
              // minHeight: "311px",
              background: "#ffffff",
            }),
          },
        }}
        placement="bottomRight"
        content={({ close }) => (
          <div>
            <InnerComp
              tableDraftView={tableDraftView}
              allColumns={props.allColumns}
              menu={menu}
              close={close}
              setView={setView}
              currentFilteringData={currentFilteringData}
              gridStructure={gridStructure}
              colOrder={colOrder}
              tableCurrentState={tableCurrentState}
              refetchPinnedViews={refetchPinnedViews}
              pinnedViewsState={pinnedViewsState}
              pinnedView={pinnedView}
            />
          </div>
        )}
        returnFocus
        autoFocus
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <>
            {pinnedView && pinnedView.length > 4 && (
              <div
                className="view-pinned"
                onClick={() => {
                  setPinnedViewsState(true);
                }}
              >
                <p className="view-pinned__name">
                  <>
                    {" "}
                    {"+"}
                    {pinnedView && pinnedView.length - 4}
                  </>
                </p>
              </div>
            )}
          </>
          <Button
            size={SIZE.compact}
            kind={KIND.secondary}
            onClick={() => {
              setPinnedViewsState(false);
            }}
          >
            <TripleDot />
          </Button>
        </div>
      </StatefulPopover>
    </div>
  );
};

export default TableViewPicker;
