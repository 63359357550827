import React, { useContext, useEffect, useMemo, useState } from "react";
import { SIZE, KIND } from "../../components/IconButton";
import Download from "../../assets/img/svg/Download";
import { useRBAC, permission } from "../../providers/RBACProvider";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  clientSummaryDownload,
  contactDownload,
  customerListDataTNC,
  customerTNC,
  fieldVisitDownload,
} from "../../services/customerSummary";
import { useSelector } from "react-redux";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import csvDownload from "json-to-csv-export";
import { TextButton } from "../TextButton";
import moment from "moment";
import { useNumericFormat } from "react-number-format";
import { useForm } from "react-hook-form";
import { StatefulPopover } from "baseui/popover";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  CONTACT_DOWNLOAD,
  DOWNLOAD_CUSTOMER_LISIT,
  DOWNLOAD_DIALER_LIST,
  DOWNLOAD_FIELD_VISIT,
} from "../../providers/RBACProvider/permissionList";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

function DownloadSummary({ queryFilter, ...props }) {
  const intl = useIntl();
  let {
    currentOrganization,
    referenceData,
    users,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    appAccessRest,
    space,
  } = useContext(MaxyfiContext);

  const { isAllowed } = useRBAC();
  let ITEMS;
  isAllowed(permission.DOWNLOAD_DIALER_LIST)
    ? (ITEMS = [
        {
          label: intl.formatMessage({
            id: "list_data",
          }),
          id: "ld",
        },
        {
          label: intl.formatMessage({
            id: "dialer_data",
          }),
          id: "dd",
        },
      ])
    : (ITEMS = [
        {
          label: intl.formatMessage({
            id: "list_data",
          }),
          id: "ld",
        },
      ]);

  let customerDlList =
    space && space?.role_permissions?.includes(DOWNLOAD_CUSTOMER_LISIT);
  let dilerDlList =
    space && space?.role_permissions?.includes(DOWNLOAD_DIALER_LIST);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });
  let gridStructureData = props.gridStructure?.table;
  const { gridStructure } = useSelector((state) => state.customerSummary);
  let filteredColumns = props.filteredColumns;

  // const { filters, globalFilters, need_action } = useSelector(
  //   (s) => s.businessMaintenanceReducer.tableCurrentState
  // );
  const businessFilters = useSelector(
    (s) => s.businessMaintenanceReducer.tableCurrentState.filters
  );
  const businessGlobalFilters = useSelector(
    (s) => s.businessMaintenanceReducer.tableCurrentState.globalFilters
  );
  const { filters, globalFilters, need_action } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );
  const { tableDraftView } = useSelector((s) => s.customerSummary);

  let findConsolidated = tableDraftView.table.find(
    (e) => e.col_id === "consolidated_action_remarks"
  );

  let finalDatas = filteredColumns.reduce((prev, curr) => {
    let gridColumn = gridStructureData.find((i) => i.accessor === curr.id);

    if (gridColumn && gridColumn.nested && Array.isArray(gridColumn.nested)) {
      return [...prev, ...gridColumn.nested];
    }
    return [...prev, curr];
  }, []);

  let relationManager = users;
  let filteringActiveRm = relationManager?.filter((i) => i.active);

  let sortingRm = filteringActiveRm?.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );

  let userRole = referenceData?.user_role;
  let changingKeys = sortingRm?.map(({ displayName, email, roleId }) => {
    let changedroleId = roleId ? userRole?.find((i) => i?.id === roleId) : null;
    let roleIdChange = changedroleId?.label;
  });

  const [isValue, setIsValue] = useState(false);

  const { tableCurrentState } = useSelector((state) => state.customerSummary);
  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  // query filter start here

  let newFilter = dynamicQueryFilter({
    gridStructure: gridStructure,
    // customerQueryFilter: queryFilter && queryFilter.display_name,
    timeZone: currentDefaultFormatDetails.time_zone,
    customerFilter: filters,
  });

  const filterDataHidden = useMemo(() => {
    if (
      !Array.isArray(gridStructureData) ||
      !Array.isArray(tableDraftView?.table)
    ) {
      return [];
    }

    const filterDataHidden = gridStructureData
      .filter((column) => {
        const hiddenCol = tableDraftView.table.find(
          (hidd) => hidd.col_id === column.accessor
        );
        return hiddenCol && hiddenCol.is_hidden === false;
      })
      .map((item) => {
        const hiddenCol = tableDraftView.table.find(
          (hidd) => hidd.col_id === item.accessor
        );
        return {
          ...item,
          order: hiddenCol?.order ?? 0, // Add order or default to 0
        };
      })
      .sort((a, b) => a.order - b.order); // Sort based on order

    return filterDataHidden;
  }, [gridStructureData, tableDraftView?.table]);

  const businessFilter = dynamicQueryFilter({
    gridStructure: _.get(props, "gridStructure.table", []),
    fieldName: "business_unit_name",
    customerQueryFilter: queryFilter && queryFilter.display_name,
    timeZone: currentDefaultFormatDetails.time_zone,
    customerFilter: businessFilters,
  });

  // query filter end here

  const Dialer_Data = () => {
    setIsValue(true);
    customerTNC({
      organization: currentOrganization,
      query: newFilter,
      filters: {
        needs_actions: [...NeedsActionFilter],
        stage: [...FilteredCustomerStage],
        ...globalFilters,
        ...appAccessRest,
        search_value: queryFilter && queryFilter.display_name,
      },
    });
  };
  const filteredGrid = useMemo(() => {
    const testArray = Array.isArray(filterDataHidden)
      ? filterDataHidden &&
        filterDataHidden?.reduce((acc, item) => {
          if (item.nested && Array.isArray(item.nested)) {
            // Merge parent item with its nested array
            const mergedItems = item.nested.map((nestedItem) => ({
              ...nestedItem,
              order: item.order,
            }));
            // Add the merged nested items to the accumulator
            return [...acc, ...mergedItems];
          } else {
            // If no nested, just add the item as is
            return [...acc, item];
          }
        }, [])
      : [];

    return testArray;
  }, [Array.isArray(gridStructureData) && gridStructure.length, finalDatas]); // Dependencies: gridStructureData and finalDatas

  // const filteredGrid = testGrid.filter((item) => {
  //   const matchedCol = tableDraftView?.table.find(
  //     (dra) => dra.col_id === item.accessor && dra.is_hidden === false
  //   );
  //   return Boolean(matchedCol); // Only include if a match with `is_hidden: false` is found
  // });

  const List_Data = () => {
    let listColumns =
      filteredGrid &&
      filteredGrid.map((e) => {
        return {
          ...e,
          title: intl.formatMessage({
            id: e.literal_id,
          }),
          id: e.accessor ? e.accessor : e.id,
          ...(e.rd_name ? { rd: e.rd_name } : {}),
        };
      });
    setIsValue(true);
    customerListDataTNC({
      organization: currentOrganization,
      pageIndex: 1,
      pageSize: 0,
      sortBy: props.querySortBy,
      columns: listColumns,
      ...(findConsolidated && !findConsolidated.is_hidden
        ? {
            is_include_consolidate: true,
          }
        : {}),
      customerFilter: newFilter,
      filters: {
        needs_actions: [...NeedsActionFilter],
        stage: [...FilteredCustomerStage],
        ...(props &&
          props.tableCurrentState &&
          props.tableCurrentState.globalFilters),
        ...appAccessRest,
      },
    });
  };

  const contactFileDownload = useMutation(
    (contactData) =>
      contactDownload({
        organization: currentOrganization,
        sortBy: props.querySortBy,
        customerFilter: newFilter,
        filters: {
          needs_actions: [...NeedsActionFilter],
          stage: [...FilteredCustomerStage],
          ...(props &&
            props.tableCurrentState &&
            props.tableCurrentState.globalFilters),
          ...appAccessRest,
        },
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        // toast.error(data.data.message);
      },
    }
  );

  const FieldVisitDataDownload = () => {
    let columns = finalDatas.map((e) => {
      return {
        title: intl.formatMessage({
          id: e.literal_id,
        }),
        id: e.accessor ? e.accessor : e.id,
        type: e.type === "LOV" ? "STRING" : e.type ? e.type : "STRING",
        ...(e.rd_name ? { rd: e.rd_name } : {}),
      };
    });

    let value = {
      columns,
      filter: { ...newFilter },
    };

    fieldVisitDownload({
      organization: currentOrganization,
      query: value,
      filters: {
        needs_actions: [...NeedsActionFilter],
        stage: [...FilteredCustomerStage],
        ...globalFilters,
        ...appAccessRest,
      },
    });
  };

  const clientSummaryList = () => {
    let columns = finalDatas.map((e) => {
      return {
        title: intl.formatMessage({
          id: e.literal_id,
        }),
        id: e.accessor ? e.accessor : e.id,
        type: e.type === "LOV" ? "STRING" : e.type ? e.type : "STRING",
        ...(e.rd_name ? { rd: e.rd_name } : {}),
      };
    });

    let value = {
      columns,
      filter: { ...businessFilter },
    };

    clientSummaryDownload({
      organization: currentOrganization,
      query: value,
      filters: {
        needs_actions: [...NeedsActionFilter],
        stage: [...FilteredCustomerStage],
        ...businessGlobalFilters,
      },
    });
  };
  useEffect(() => {
    if (isValue == true) {
      setTimeout(() => {
        setIsValue(false);
      }, 2000);
    }
  }, [isValue]);
  return (
    <>
      {/* {isAllowed(permission.DOWNLOAD_DIALER_LIST)? */}
      {props && props?.fileName === "Client Summary" ? (
        <>
          {" "}
          <div
            style={{ width: "39px", height: "35px" }}
            onClick={() => clientSummaryList()}
          >
            <TextButton
              size={SIZE.mini}
              overrides={{
                LoadingSpinner: {
                  style: ({ $theme }) => ({
                    height: "18px",
                    width: "18px",
                  }),
                },
              }}
            >
              <Download />
            </TextButton>
          </div>
        </>
      ) : (
        <StatefulPopover
          focusLock
          placement="DownloadSummary"
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                borderRadius: "5px",
                marginRight: "30px",
                padding: "5px",
                marginTop: "5px",
                backgroundColor: "#fff",
              }),
            },
          }}
          content={({ close }) => (
            <div className="user-popover__btn">
              <RBACWrapper role={DOWNLOAD_CUSTOMER_LISIT} type="PERMISSION">
                <TextButton
                  isLoading={loading}
                  disabled={loading}
                  kind={KIND.tertiary}
                  $style={{
                    cursor: "pointer",
                    padding: "5px 30px",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "12px",
                    backgroundColor: "#fff",
                  }}
                  onClick={async () => {
                    // setLoading(true);
                    if (
                      _.get(props, "getCustomerCount.data.data.totalDocs", 0) <=
                      10000
                    ) {
                      close();
                      List_Data();
                    } else {
                      toast.error(
                        "Max download limit is 10K, Kindly retry with < 10K records"
                      );
                    }
                    // let data = await props
                    //   .service({
                    //     organization: currentOrganization,
                    //     pageIndex: 1,
                    //     sortBy: props.querySortBy,
                    //     pageSize: 0,
                    //     ...(findConsolidated && !findConsolidated.is_hidden
                    //       ? {
                    //           is_include_consolidate: true,
                    //         }
                    //       : {}),
                    //     customerFilter: newFilter,
                    //     filters: {
                    //       needs_actions: [...NeedsActionFilter],
                    //       stage: [...FilteredCustomerStage],
                    //       ...(props &&
                    //         props.tableCurrentState &&
                    //         props.tableCurrentState.globalFilters),
                    //       ...appAccessRest,
                    //     },
                    //   })
                    //   .then((res) => {
                    //     setData(res?.data?.docs);
                    //     setLoading(false);
                    //     return res.data.docs;
                    //   })
                    //   .catch((error) => {
                    //     return [];
                    //   });

                    // let mockData = [];
                    // for (let row of data) {
                    //   let rsObj = {};

                    //   for (let k of finalDatas) {
                    //     let key = k.literal_id;
                    //     key = intl.formatMessage({
                    //       id: key,
                    //     });
                    //     if (
                    //       props?.defaultCurrency &&
                    //       (k.cell_type === "CURRENCY" ||
                    //         k.cell_type === "CURRENCY_NEW" ||
                    //         k.cell_type === "CURRENCYFEEAMOUNT")
                    //     ) {
                    //       key = `${key} ${""} (in ${props.defaultCurrency})`;
                    //     }

                    //     let value = _.get(row, k.accessor || k.path, "");
                    //     switch (k.cell_type) {
                    //       case "TIMECELL": {
                    //         const orgRef = referenceData?.organizations?.find(
                    //           ({ id }) => id === currentOrganization
                    //         );
                    //         const timeZone = orgRef?.time_zone;
                    //         let date = moment
                    //           ?.utc(value)
                    //           ?.tz(timeZone)
                    //           ?.format(
                    //             currentDefaultFormatDetails?.date_format
                    //           );
                    //         if (value && k.accessor !== "vidt") {
                    //           //   value = `${"$"}${new Intl.NumberFormat().format(value?.value)}`;
                    //           value = intl.formatTime(value);
                    //         } else if (k.accessor === "vidt" && value) {
                    //           value = `${date} & ${intl.formatTime(value)}`;
                    //         }
                    //         break;
                    //       }

                    //       // Next Action Date
                    //       case "DATEORGTZ": {
                    //         const orgRef = referenceData?.organizations?.find(
                    //           ({ id }) => id === currentOrganization
                    //         );
                    //         const timeZone = orgRef?.time_zone;
                    //         let date = moment
                    //           ?.utc(value)
                    //           ?.tz(timeZone)
                    //           ?.format(
                    //             currentDefaultFormatDetails?.date_format
                    //           );
                    //         if (value) {
                    //           value = date;
                    //         }
                    //         break;
                    //       }
                    //       // value && value ? (
                    //       //   (value = intl.formatDate(date, {
                    //       //     year: "numeric",
                    //       //     month: "numeric",
                    //       //     day: "numeric",
                    //       //   }))
                    //       // ) : (
                    //       //   <></>
                    //       // );
                    //       case "DATE_STATUS": {
                    //         let dateOn = moment
                    //           .utc(value)
                    //           .format(currentDefaultFormatDetails?.date_format);
                    //         if (value) {
                    //           value = dateOn;
                    //         }
                    //         break;
                    //       }
                    //       case "DATE": {
                    //         let dateOn = moment
                    //           .utc(value)
                    //           .format(currentDefaultFormatDetails?.date_format);
                    //         if (value) {
                    //           value = dateOn;
                    //         }
                    //         // value && value ? (
                    //         //   (value = intl.formatDate(dateOn, {
                    //         //     year: "numeric",
                    //         //     month: "numeric",
                    //         //     day: "numeric",
                    //         //   }))
                    //         // ) : (
                    //         //   <></>
                    //         // );
                    //         break;
                    //       }

                    //       case "REFERENCE_VALUE": {
                    //         const rfValue = referenceData[
                    //           k?.rd_name || k?.rdName
                    //         ]?.find(({ id }) => {
                    //           return id === value;
                    //         });

                    //         if (
                    //           (k && k.rdName === "business_unit_list") ||
                    //           k?.rd_name === "business_unit_list"
                    //         ) {
                    //           value = rfValue && rfValue.client_name;
                    //         } else if (value) {
                    //           value = rfValue?.label;
                    //         }
                    //         // value && value ? (value = user?.displayName) : <></>;

                    //         break;
                    //         //
                    //       }

                    //       case "USER": {
                    //         const user = users.find(({ id }) => {
                    //           return id === value;
                    //         });

                    //         if (value) {
                    //           value = user?.displayName;
                    //         }
                    //         // value && value ? (value = user?.displayName) : <></>;

                    //         break;
                    //       }

                    //       case "BOOLEN_TYPE": {
                    //         if (value === true) {
                    //           value = "Yes";
                    //         } else if (value === false) {
                    //           value = "No";
                    //         } else {
                    //           value = "";
                    //         }
                    //         break;
                    //       }

                    //       case "CURRENCY": {
                    //         if (value) {
                    //           //   value = `${"$"}${new Intl.NumberFormat().format(value?.value)}`;
                    //           value = `${numberFormat.format(
                    //             `${value?.value}`
                    //           )} ${
                    //             props.defaultCurrency ? "" : value?.currency
                    //           }`;
                    //         }
                    //         break;
                    //       }
                    //       case "CURRENCYFEEAMOUNT": {
                    //         if (value) {
                    //           //   value = `${"$"}${new Intl.NumberFormat().format(value?.value)}`;
                    //           value = `${numberFormat.format(
                    //             `${value?.value}`
                    //           )} ${
                    //             props.defaultCurrency ? "" : value?.currency
                    //           }`;
                    //         }
                    //       }
                    //     }
                    //     rsObj[key] = value;
                    //   }

                    //   mockData.push(rsObj);
                    // }

                    // csvDownload(
                    //   mockData,
                    //   `${`${props.fileName} ${moment().format(
                    //     currentDefaultFormatDetails?.date_format
                    //   )}${" "}${moment().format("hh:mm:ss a")}.csv`}`
                    //   // (moment().format("MM-DD-YYYY"), moment().format("hh:mm:ss a"))

                    //   // ((__filename = "customerSummary.csv"))
                    // );
                  }}
                >
                  <FormattedMessage id="list_data">list_data</FormattedMessage>
                </TextButton>
              </RBACWrapper>
              <RBACWrapper role={DOWNLOAD_DIALER_LIST} type="PERMISSION">
                <TextButton
                  kind={KIND.tertiary}
                  isLoading={isValue}
                  disabled={isValue}
                  $style={{
                    cursor: "pointer",
                    padding: "5px 30px",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "12px",
                    backgroundColor: "#fff",
                  }}
                  fullWidth
                  onClick={() => {
                    if (
                      _.get(props, "getCustomerCount.data.data.totalDocs", 0) <=
                      30000
                    ) {
                      close();
                      Dialer_Data();
                    } else {
                      toast.error(
                        "Max download limit is 30K, Kindly retry with < 30K records"
                      );
                    }
                  }}
                >
                  <FormattedMessage id="dialer_data">
                    dialer_data
                  </FormattedMessage>
                </TextButton>
              </RBACWrapper>

              <RBACWrapper role={DOWNLOAD_FIELD_VISIT} type="PERMISSION">
                <TextButton
                  kind={KIND.tertiary}
                  isLoading={isValue}
                  disabled={isValue}
                  $style={{
                    cursor: "pointer",
                    padding: "5px 30px",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "12px",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    if (
                      _.get(props, "getCustomerCount.data.data.totalDocs", 0) <=
                      10000
                    ) {
                      close();
                      FieldVisitDataDownload();
                    } else {
                      toast.error(
                        "Max download limit is 10K, Kindly retry with < 10K records"
                      );
                    }
                  }}
                >
                  <FormattedMessage id="field_visit_data">
                    field_visit_data
                  </FormattedMessage>
                </TextButton>
              </RBACWrapper>

              <RBACWrapper role={CONTACT_DOWNLOAD} type="PERMISSION">
                <TextButton
                  kind={KIND.tertiary}
                  isLoading={contactFileDownload.isLoading}
                  disabled={contactFileDownload.isLoading}
                  $style={{
                    cursor: "pointer",
                    padding: "5px 30px",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "12px",
                    backgroundColor: "#fff",
                  }}
                  onClick={async () => {
                    await contactFileDownload.mutateAsync();
                    close();
                  }}
                >
                  <FormattedMessage id="contact_download">
                    contact_download
                  </FormattedMessage>
                </TextButton>
              </RBACWrapper>
            </div>
          )}

          // content={({ close }) => (
          //   <StatefulMenu
          //     items={ITEMS}
          //     // items={ITEMS}
          //     onItemSelect={async (params) => {
          //       close();
          //

          // }
          //     }

          //     // }}
          //     overrides={{
          //       List: {
          //         style: {
          //           // height: "160px",
          //           width: "110px",
          //           marginTop: "5px",
          //           marginRight: '2px',
          //           borderRadius: "5px"
          //         },
          //       },
          //     }}
          //   />
          // )}
        >
          {/* <RBACWrapper       role={
            apiGroups=[DOWNLOAD_DIALER_LIST,DOWNLOAD_CUSTOMER_LISIT]
          } type="PERMISSION"> */}

          {customerDlList ? (
            <div style={{ width: "39px" }}>
              <TextButton
                size={SIZE.mini}
                overrides={{
                  LoadingSpinner: {
                    style: ({ $theme }) => ({
                      height: "18px",
                      width: "18px",
                    }),
                  },
                }}
              >
                <Download />
              </TextButton>
            </div>
          ) : dilerDlList ? (
            <div style={{ width: "39px" }}>
              {" "}
              <TextButton
                size={SIZE.mini}
                overrides={{
                  LoadingSpinner: {
                    style: ({ $theme }) => ({
                      height: "18px",
                      width: "18px",
                    }),
                  },
                }}
              >
                <Download />
              </TextButton>
            </div>
          ) : customerDlList && dilerDlList ? (
            <div style={{ width: "39px" }}>
              {" "}
              <TextButton
                size={SIZE.mini}
                overrides={{
                  LoadingSpinner: {
                    style: ({ $theme }) => ({
                      height: "18px",
                      width: "18px",
                    }),
                  },
                }}
              >
                <Download />
              </TextButton>
            </div>
          ) : (
            <></>
          )}

          {/* </RBACWrapper> */}
        </StatefulPopover>
      )}

      {/* :<></>} */}
    </>
  );
}

export default DownloadSummary;
