import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useMutation } from "react-query";

import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import { ACT_UNSUCCESSFUL } from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import { ADD_LIST_VALUES } from "../../../../providers/RBACProvider/permissionList";
import { Typography } from "../../../../components_v2";

const CallUnsuccessfulForm = (props) => {
  const {
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    isIncrementor = true,
    is_inbound_call = false,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();

  let {
    referenceData,
    currentDefaultFormatDetails,
    currentOrganization,
    space,
  } = useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {},
  });

  const [reason] = watch(["call_unsuccessful_reason"]);

  useEffect(() => {
    if (reason && reason[0] && reason[0].id === "voice_mail") {
      setValue("is_increment_follow_up", true);
    } else {
      setValue("is_increment_follow_up", false);
    }
  }, [reason && reason[0] && reason[0].id]);

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: () => {
        onSuccess();
      },
    }
  );

  const onSubmit = async (data) => {
    const { followup_date, call_unsuccessful_reason } = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...formValues,
      ...(isIncrementor ? { content: "-" } : {}),
      call_unsuccessful_reason: getSelectValues(call_unsuccessful_reason),
      ...(followup_date && reason && reason[0] && reason[0].id !== "voice_mail"
        ? {
            followup_date: moment
              .tz(
                `${followup_date.getFullYear()}-${
                  followup_date.getMonth() + 1
                }-${followup_date.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...getActionReminder(data, {
        is_calendar: false,
        is_consent_enabled: false,
        is_followup: false,
      }),
      mention_users: uniqueMentionedUsers,
    };

    await serviceMutation.mutateAsync(values);
  };

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <Typography subType="regular">Unsuccessfull Reason</Typography> */}
      <div
        style={{
          // display: "flex",
          gap: "5px",
          marginTop: "20px",
          width: "300px",
        }}
      >
        <Controller
          name="call_unsuccessful_reason"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              requiredAstric={true}
              {...field}
              clearable={false}
              name={field.name}
              size={SIZE.mini}
              creatable={creatablePermission ? true : false}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "unsuccessful_reason",
              })}
              placeholder={intl.formatMessage({
                id: "unsuccessful_reason",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={
                referenceData["call_unsuccessful_reason"].sort((a, b) =>
                  a?.id?.localeCompare(b?.id)
                ) || []
              }
            />
          )}
        />
      </div>

      <Typography subType="regular">Follow-up On:</Typography>
      <div
        style={{
          marginTop: "5px",
          display: "flex",
          gap: "5px",
          width: "300px",
          ...(reason && reason[0] && reason[0].id === "voice_mail"
            ? { userSelect: "none", pointerEvents: "none" }
            : {}),
        }}
      >
        <div
          className="co-call-outcome-btn co-call-outcome-btn--fit"
          onClick={() => {
            setValue("followup_date", moment().startOf("day").toDate());
          }}
        >
          Today
        </div>
        <div
          className="co-call-outcome-btn co-call-outcome-btn--fit"
          onClick={() => {
            setValue(
              "followup_date",
              moment().add(1, "days").startOf("day").toDate()
            );
          }}
        >
          Tomorrow
        </div>

        <Controller
          name="followup_date"
          control={control}
          render={({ field }) => (
            <DateRangeSelect
              {...field}
              name={field.name}
              size={SIZE.mini}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "followup_date",
              })}
              placeholder={intl.formatMessage({
                id: `${currentDefaultFormatDetails.date_format}`,
              })}
              value={field.value}
              onChange={(e) => field.onChange(e.date)}
              minDate={new Date()}
            />
          )}
        />
      </div>

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={formValues.action_type}
        outcome={ACT_UNSUCCESSFUL}
        is_consent_enabled={false}
        is_calendar={false}
        is_followup={false}
        is_incrementor={props.isIncrementor}
        is_consent={props.isConsent}
        is_valid_from_contact={
          formValues.action_type === "FIELD_VISIT" ? true : false
        }
        // is_inbound_call={
        //   formValues.is_inbound_call ? true : false || is_inbound_call
        // }
      />

      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={serviceMutation.isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </TextButton>
        <TextButton
          size="mini"
          disabled={serviceMutation.isLoading}
          isLoading={serviceMutation.isLoading}
        >
          Save
        </TextButton>
      </div>
    </form>
  );
};

export default CallUnsuccessfulForm;
