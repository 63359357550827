import React, { useEffect } from "react";
import _ from "lodash";
import { useContext } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { ParagraphMedium } from "baseui/typography";
import moment from "moment";
import { useForm } from "react-hook-form";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useUpdateCustomFields } from "../../../services/customerOverviewContacts";
import getSelectValues from "../../../utils/getSelectValues";
import { TextButton } from "../../../components/TextButton";
import setSelectValues from "../../../utils/setSelectValues";
import InputTypes from "./InputTypes";
import InvLevelitemizationFields from "./InvLevelitemizationFields";

const InvLevelCustomFieldsTab = ({
  customFields = {},
  fieldLevel = [],
  width = "",
  wrapWidth = "",
  invId = null,
  isSave = false,
}) => {
  const { currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  let itemData = [
    ...fieldLevel,
    {
      section: "Itemization",
    },
  ];

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let result = {};
  for (let obj of itemData) {
    let section = obj.section;
    if (result.hasOwnProperty(section)) {
      if (obj && !obj.idb) {
        result[section].push({ ...obj });
      }
    } else {
      if (obj && !obj.idb) {
        result[section] = [{ ...obj }];
      }
    }
  }

  let getObjectKey = Object.keys(result);

  const [isSectionActive, setIsSectionActive] = useState(
    getObjectKey && getObjectKey[0]
  );

  useEffect(() => {
    if (customFields && customFields?.custom_field) {
      for (let i = 0; i < itemData.length; i++) {
        let path = itemData[i].path;
        if (customFields && customFields.custom_field?.hasOwnProperty(path)) {
          if (itemData?.[i]?.data_type === "DATE") {
            if (
              customFields &&
              customFields?.custom_field &&
              customFields?.custom_field?.[path]
            ) {
              setValue(
                path,
                new Date(customFields && customFields?.custom_field?.[path]) ||
                  null
              );
            }
          } else if (
            itemData?.[i]?.data_type === "TEXT" &&
            itemData?.[i]?.options?.length > 0
          ) {
            if (path && customFields && customFields?.custom_field?.[path]) {
              setValue(
                path,
                setSelectValues(
                  (customFields && customFields?.custom_field?.[path]) || []
                )
              );
            }
          } else if (itemData?.[i]?.data_type === "AMOUNT") {
            if (
              path &&
              customFields &&
              customFields?.custom_field?.[path]?.value
            ) {
              setValue(
                `${path}.value`,
                Number(
                  _.get(customFields, `custom_field.[${path}].value`, 0)
                ) &&
                  Number(
                    _.get(customFields, `custom_field.[${path}].value`, 0)
                  ).toFixed(2)
              );
              setValue(
                `${path}.currency`,
                customFields?.custom_field?.[path]?.currency
              );
            }
          } else {
            setValue(path, customFields?.custom_field?.[path]);
          }
        }
      }
    }
  }, [customFields?.custom_field, itemData.length]);

  const { mutateAsync, isLoading } = useUpdateCustomFields();

  let onSubmit = async (data) => {
    let value = {};

    let index =
      Array.isArray(itemData) &&
      itemData.findIndex((ele) => ele.section === "Itemization");
    itemData =
      Array.isArray(itemData) && itemData.filter((val, i) => i != index);

    for (let i = 0; i < itemData.length; i++) {
      let path = itemData?.[i]?.path;
      let dataType = itemData?.[i]?.data_type;
      let isVisible = itemData?.[i]?.is_hidden;
      let keys = path?.split(".");
      let current = data;
      for (let j = 0; j < keys?.length; j++) {
        if (keys && keys[j] && current.hasOwnProperty(keys[j])) {
          current = current[keys[j]];
        } else {
          current = "Not-valid";
        }
      }

      if (!isVisible && current !== "Not-valid") {
        if (dataType === "DATE") {
          if (current) {
            value[path] = moment
              .tz(
                `${current?.getFullYear()}-${
                  current?.getMonth() + 1
                }-${current?.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf();
          }
        } else if (dataType === "AMOUNT") {
          if (current.value) {
            value[path] = current;
          }
        } else if (dataType === "TEXT" && itemData?.[i]?.options?.length > 0) {
          value[path] = getSelectValues(current);
        } else {
          if (current) {
            value[path] = current;
          }
        }
      }
    }

    let values = { custom_field: { ...value } };

    await mutateAsync(
      {
        values,
        customerId: invId,
        organization: currentOrganization,
        entity: "invoices",
      },
      {
        onError: (error) => {
          if (error.response) {
            let { data } = error.response;
            toast.error(data.message);
          }
        },
        onSuccess: (data) => {
          // queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
        },
      }
    );
  };

  const sortedData =
    Array.isArray(result?.[isSectionActive]) &&
    result[isSectionActive]?.length > 0
      ? result[isSectionActive].sort((a, b) => a.position - b.position)
      : [];

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: width }}>
      <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
        {Object.keys(result)?.map((inv, i) => {
          return (
            <div>
              <div
                className={`inv_custom_tab_mx ${
                  isSectionActive === inv && "--custom_inv_active"
                }`}
                onClick={() => {
                  setIsSectionActive(inv);
                }}
              >
                <ParagraphMedium>{inv}</ParagraphMedium>
              </div>
            </div>
          );
        })}
      </div>
      {isSectionActive === "Itemization" ? (
        <>
          <InvLevelitemizationFields
            customFields={customFields}
            invoiceId={invId}
          />
        </>
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              gap: "15px",
              flexWrap: "wrap",
              alignItems: "flex-start",
              marginBottom: "10px",
            }}
          >
            {sortedData.map((e) => {
              return (
                !e.is_hidden && (
                  <div style={{ width: wrapWidth }}>
                    <InputTypes
                      name={e.name}
                      control={control}
                      currentDefaultFormatDetails={currentDefaultFormatDetails}
                      type={e.data_type}
                      path={e.path}
                      options={e.options}
                      disabled={e.is_editable}
                    />
                  </div>
                )
              );
            })}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {fieldLevel && fieldLevel.length > 0 && isSave ? (
              <>
                <TextButton size={"mini"}>Save</TextButton>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </form>
  );
};

export default InvLevelCustomFieldsTab;
