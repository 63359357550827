export default {
  role_maintenance_form: "Add New Role Maintenance",
  edit_role_maintenance_form: "Edit Role Maintenance",
  view_role_maintenance_form: "View Role Maintenance",
  added_permissions: "Added Permission",
  removed_permissions: "Removed Permission",
  menu_access: "Menu Access",
  linked_user_count: "Linked user count",
  role_description: "Role Description",
  role_name: "Role Name",
  report_name: "Report Name",
  add_role_maintenance: "Add Role Maintenance",
  edit_role_maintenance: "Edit Role Maintenance",
  role_maintenance: "Role Maintenance",
  slab_name: "Slab Name",
  slab_based_on: "Range Parameter",
  contact_type: "Contact Type",
  payment_allocation_type: "Payment Allocation Type",
  debtor_payment_instruction_2: "Debtor Payment Instruction 2",
  portal_payment_type: "Portal Payment Type",
  debtor_payment_instruction_1: "Debtor Payment Instruction 1",
  relation_manager: "Relation Manager",
  rel_manager_address: "Relation Manager Address",
  rel_manager_phone: "Relation Manager Phone",
  business_unit_name: "Client",
  business_unit_code: "Client Code",
  cilent_display_name: "Client Display Name",
  business_registration_number: "Client Registration Number",
  commission_type: "Commission Type",
  flat_fee_percentage: "Flat Fee Percentage",
  commission_slab: "Commission Slab",
  invoice_type: "Invoice Type",
  invoice_start_date: "Invoice Start Date",
  hearing_date: "Hearing Date",
  next_billing_date: "Next Billing Date",
  invoice_frequency: "Invoice Frequency",
  invoice_payment_instruction_1: "Invoice Payment Instruction 1",
  invoice_payment_instruction_2: "Invoice Payment Instruction 2",
  invoice_terms_and_condition: "Invoice Terms and Condition",
  branding: "Branding",
  invoice_taxes: "Invoice Taxes",
  add_business_unit: "Add Client",
  view_business_unit: "View Client",
  edit_business_unit: "Edit Client",
  motion_type: "Type",
  business_unit_maintenance: "Client Maintenance",
  delivered_list: "Delivered List",
  bounced_list: "Bounced List",
  last_read_at: "Last Read At",
  customer_categorization: "Consumer Categorization",
  paid_to: "Paid To",
  paid_amount: "Paid Amount",
  choose_template: "Choose Template",
  type_review: "Type",
  release_promise_date: "Release Promise Hold",
  business_unit: "Client",
  business_location: "Client Location",
  business: "Client & Location",
  customer_status: "Consumer Status",
  status_status_date: "Status/Status Date",
  EMAIL_REPLY: "Email Reply",
  customer_comment: "Consumer Comment",
  api_token: "Api Token",
  sub_domain: "Sub Domain",
  checkin_report: "Checkin Report",
  recurring_report: "Recurring Report",
  edit_invoice: "Edit Invoice",
  edit_recurring: "Edit Recurring Invoice",
  add_recurring_invoice: "Add Recurring Invoice",
  add_invoice: "Add Invoice",
  invoice_notes: "Invoice Notes",
  invoice_terms: "Invoice Terms",
  invoice_footer: "Invoice Footer",
  frequency: "Frequency",
  select_tax: "Select Tax",
  select_product: "Select Product",
  next_execution_date: "Next Execution Date",
  recover_from_customer: "Recover From",
  billing_address: "Billing Address",
  shipping_address: "Shipping Address",
  payment_term: "Payment Term",
  advance_amount: "Advance Amount",
  po_number: "PO Number",
  discount: "Discount",
  other_information: "Other Information",
  subtotal: "Subtotal",
  paid: "Paid",
  balance_due: "Balance Due",
  internal_notes: "Remarks",
  two_tax_rate: "Two Tax Rate",
  one_tax_rate: "One Tax Rate",
  three_tax_rate: "Three Tax Rate",
  invoicing: "Invoicing",
  measure: "Measure",
  add_motion: "Add Motion",
  invoice_item_maintenance: "Invoice Item Maintenance",
  invoice_level_tax_rate: "Invoice Level Tax Rates",
  invoice_item_level_tax_rate: "Invoice Item Level Tax Rates",
  rate: "Rate",
  nature_of_tax: "Nature of Tax",
  subscription_id: "Subscription Id",
  customer_notes: "Consumer Notes",
  item_amount: "Item Amount",
  end_date: "End Date",
  fixed_amount: "Fixed Amount",
  usage_amount: "Usage Amount",
  adhoc_amount: "Adhoc Amount",
  api_key: "Api key",
  api_base_url: "Api base url",
  allocation_invoice: "Allocation Invoice",
  sender_id: "Sender Id",
  number: "Number",
  min_call: "Minimum Call",
  max_call: "Maxmium Call",
  allocated_amount_pending_allocation: "Allocation/Pending Allocate",
  credit_limit_breach_perc: "Credit Limit Percentage",
  credit_limit_status: "Credit Limit Status",
  compliance_breach_report: "Compliance Breach Report",
  search: "Search",
  source: "Source",
  key_id: "Key Id",
  client_payment_ul: "Client Payment URL",
  key_secret: "Key Secret",
  last_action: "Last Action",
  next_action: "Next Action",
  due: "Due",
  overdue: "OverDue",
  total: "Total",
  sync_status: "Sync Status",
  credit_limit: "Credit Limit",
  call_unsuc_is_send_confirmation_message: "Send Confirmation  Message",
  call_un_is_hold_workflow: "Pause workflow till follow up date",
  call_unsuccessfull_call: "Pause workflow till Call Back Date",
  is_send_confirmation_message_ptp: "Send Confirmation  Message",
  is_hold_workflow_ptp: "Pause workflow till Promise Date",
  is_send_confirmation_message_cbl: "Send Confirmation Message",
  is_hold_workflow_cbl: "Pause workflow till Call Back Date",
  send_promise_follow_up_message_cbl: "Send Call Back Follow up Message",
  type_limit: "Type Limit",
  call_unsuccessful_till: "Call Unsuccessful Till",
  call_unsuccessful_till_date: "Send Confirmation Message",
  pause_workflow_till_call_back_date: "Pause workflow till Call Back Date",
  pause_workflow_till_promise_date: "Pause workflow till Promise Date",
  promise_to_pay_confirmation: "Promise to Pay Confirmation  Message",
  pause_workflow_till_call_back_confirmation_message:
    "Send Confirmation Message",
  total_inv_count_: "Total Invoice #",
  rel_manager_email: "Relation Manager (Email Address)",
  customer_id: "Consumer ID",
  workflow_start_date: "Workflow Start Date",
  total_invoice_amount: "Total Invoice Amount",
  enter_your_timezone: "Enter Your Timezone",
  pause_workflow_till_follow_up_message: "Send Promise Follow Up Message",
  timezone: "Default Timezone",
  currency_cur: "Default Currency",
  enter_your_currency: "Enter Your Currency",
  enter_your_company_name: "Enter Your Company Name",
  enter_your_country: "Enter Your Country",
  confirm_password: "Confirm Password",
  enter_password: "Enter Password",
  enter_confirm_password: "Enter Confirmed Password",
  business_email_account: "Business Email Account",
  enter_your_first_name: "Enter Your First Name",
  UNREAD_EMAILS: "Unread Emails",
  select_filter: "Select Filter",
  search_by_cus_name: "Search By Consumer Name",
  ein_gi: "Business Registration Number",
  email_id: "Email Address",
  address_line_1_floor_number_door_street_details: "Address Line 1",
  action_on_hold: "Action on hold",
  legal_pending: "Legal In Progress",
  cus_max_delay_days: "Max Delay Days",
  message_medium: "Message Medium",
  hold_workflow_till_dispute_date: "Pause Workflow till Dispute date",
  sb: "Subject",
  bcc_e: "BCC",
  cc_e: "CC",
  content_body: "Content Body",
  date_and_time: "Date and Time",
  save_mapping_for_future_reference: "Save mapping for future reference",
  update_debt_info: "If Record Exists, Update debt Info",
  update_contact_info: "If Record Exists, Update Contact Info",
  closed_with_active:
    "If Record Exists, Mark Closed Records with Active Status and update full data",
  record_with_new_recode:
    "If Record Exists with Closed Status, Load Data into a new Record",
  attachments: "Attachments",
  partial_amount: "Partial Amount",
  full_amount: "Full Amount",
  PARTIAL: "Partial",
  summary_view_of_invoice_download: "Linked Invoice Numbers",
  hold_workflow_till_review_date: "Pause workflow till review date",
  BROKEN: "Broken",
  KEPT: "Kept",
  IN_PROGRESS: "In Progress",
  mandatory_system_fields: "Mandatory System Fields",
  select_template: "Apply Mapping",
  invoice_amount: "Invoice Amount",
  ccy: "CCY",
  issued_date: "Issued Date",
  allow_invoice_download: "Allow Invoice Download",
  display_paid_invoices: "Display Paid Invoices",
  adjusted_credit_note: "Display Fully Adjusted Credit Notes",
  historical_chat: "Historical Chat",
  files_transfered: "Files Transfered",
  amnt_restriction: "Payment Amount Restriction",
  instalment_extension: "Maximum Installment Extension",
  payment_frequency: "Paymnet Plan Frequency",
  payment_residual: "Payment Plan Residual",
  restrict_date: "Maximum Payment Extension",
  percentage: "Min Payment Percentage",
  duration: "Call back Duration",
  start: "Start Time",
  end: "End Time",
  send_promise_follow_up_message: "Send Promise Follow Up Message",
  CUSTOMER: "Consumer Level",
  INVOICE: "Invoice Level",
  ORGANIZATION: "Organization Level",
  BUSINESS_UNIT: "Client Level",
  reason_to_skip: "Reason To Skip",
  audit: "Audit",
  issued_amount: "Issued Amount",
  linked_invoices: "Linked Invoices",
  linked_amount: "Linked Amount",
  available_amount: "Available Amount",
  audit_user: "Audit User",
  dispute_detailed_text: "Dispute Detailed Text",
  dispute_category: "Dispute Category",
  hold_till_date: "Pause Till Date",
  notes_category: "Notes Category",
  date: "Date",
  method: "Method",
  expected_date: "Expected Date",
  created_by: "Created By",
  signed_date_time: "Signed Date & Time",
  e_custom_text: "E-Signature Custom Text",
  comment: "Comment",
  expected_payment_date: "Expected Payment Date",
  invoice_number: "Invoice Number",
  invoice_date: "Invoice Date",
  commission_percentage: "Commission Percentage",
  commission_amount: "Commmission Amount",
  payment_amount: "Payment Amount",
  original_amount: "Original Amount",
  adjusted_amount: "Adjusted Amount",
  maximum_no_of_installments: "Maximum No of Instalments",
  payment_alloation_priority: "Payment Allocation Priority",
  invoice_due_date: "Invoice Due Date",
  currency_code: "Currency Code",
  received_amount: "Received Amount",
  capture_by: "Capture By",
  capture_details: "Capture Details",
  details: "Details",
  disputes: "Disputes",
  review_date: "Review Date",
  dispute_status: "Review Status",
  captured_by: "Captured By",
  date_time: "Date & Time",
  activity: "Activity",
  skip_workflow_action_till_next_action_date:
    "Skip Workflow Action till Next action date ?",
  reciever: "Receiver",
  next_action_type: "Next Action Type",
  hold_workflow_till_promise_date: "Pause workflow till Promise Date",
  user_comments: "User Comments",
  invoice_total_amount: "Amount",
  select_orientation: "Select Orientation",
  choose_header: "Choose Header",
  choose_header_premium: "Choose Header (premium Feature)",
  choose_footer_premium: "Choose Footer (premium Feature)",
  is_skip_workflow_action: "Is Skip Workflow Action",
  choose_footer: "Choose Footer",
  top_margin: "Top Margin",
  bottom_margin: "Bottom Margin",
  right_margin: "Right Margin",
  left_margin: "Left Margin",
  choose_action: "Choose Action",
  action_date: "Action Date",
  override_workflow: "Override Workflow",
  account_name: "Account Name",
  routing_number: "Routing Number",
  action_type: "Action Type",
  account_number: "Account Number",
  code: "Code",
  phone_number: "Phone Number",
  choose_relation_manager: "Choose Relation Manager",
  choose_workflow: "Choose Workflow",
  org_name: "Organization Name",
  ein: "EIN",
  ein_placeholder: "XX-XXXXXXX",
  email_value: "Email Value",
  address_line_1: "Address Line 1",
  address_line_2: "Address Line 2",
  city: "City",
  state: "State",
  country: "Country",
  zip_code: "Zip Code",
  start_time: "Start Time",
  end_time: "End Time",
  time_zone: "Time Zone",
  industry_sector: "Industry Sector",
  org_url: "Organization URL",
  cli_sector: "Client Sector",
  dashboard: "Dashboard",
  default_currency: "Default Currency",
  organization_settings: "Organization Settings",
  organizations: "Organizations",
  department: "Department",
  authentication_type: "Authentication Type",
  created_on: "Created On",
  template_id: "Choose Template",
  is_credit_note: "Credit Note",
  is_authentication: "Authentication",
  is_invoice: "Invoice",
  is_payment: "Payment",
  is_message: "Message",
  is_callback: "Call Back Days",
  is_request_back: "Request Payment Time",
  is_payment_plan: "Request Payment Plan",
  is_per_payment_plan: "Predefined Payment Plans",
  of_payment: "# of payment",
  is_allow_invoice_download: "Invoice Download",
  cus: "Consumer Portal Link",
  allow_invoice_download: "Allow Invoice Download",
  display_invoice: "Display Invoice",
  display_fully_adjusted_credit_notes: "  Display Fully Adjusted Credit Notes",
  pay_all_dues: "Pay All Dues",
  search_invoice: "Search Invoice",
  add_report: "Add Report",
  report_schema: "Report Schema",
  total_amount: "Total Amount",
  invoice_enable: "Invoice Enable",
  authentication: "Authentication",
  allocated_amount: "Allocated Amount",
  add_credit_notes: "Add Credit Notes",
  edit_credit_notes: "Edit Credit Notes",
  pay_overdues: "Pay Overdue",
  full_amount: "Full Amount",
  adhoc_payment: "Adhoc Payment",
  display_historical_chat: "Display Historical Chat",
  allow_transfered: " Allow Files To be Transfered",
  payment: "Payment",
  message: "Message",
  call_back: "Call Back",
  request_back: "Request Back",
  remarks: "Remarks",
  amount: "Amount",
  issue_date: "Issue Date",
  customer: "Consumer",
  credit_note_id: "Credit Note Id",
  credit_note: "Credit Note",
  currency: "Currency",
  type: "Types of Notes",
  is_enable_alert_and_follow_up: "",
  domain_name: "Domain Name",
  user_name: "User Name",
  host_name: "Host Name",
  port: "Port",
  password: "Password",
  organisation_name: "Organisation Name",
  follow_up_date: "Follow up Date",
  notes: "User Notes",
  customer_setting: "Consumer Setting",
  customer_name: "Consumer Name",
  status: "Status",
  no_of_days: "Days",
  reason_pause: "Reason",
  modified: "Modify",
  resctrictions: "Restrictions",
  work_queue: "Work Queue",
  user_name: "User Name",
  choose_type: "Choose Type",
  first_name: "First Name",
  second_name: "Second Name",
  last_name: "Last Name",
  name: "Name",
  role: "Role",
  phone: "Phone",
  business_unit_code: "Client Code",
  cl_reg_no: "Client Registration Number",
  industry_sector: "Client Sector",
  phone_new: "Phone #",
  master_child: "Master / Child",
  no_of_records_l_2: "# of Records (L-2)",
  no_of_records_l_1: "# of Records (L-1)",
  no_of_records: "# of Records",
  cli_status: "Client Status",
  first_file_d: "First File Date",
  first_file_d_dynamic: "First File Date Dynamic",
  day_since_l_file: "Days Since Last File",
  month_year_l: "Month & Year (L)",
  month_year_l_dynamic: "Month & Year (L) Dynamic",
  contract_sign_date: "Contract Signed Date",
  contract_sign_date_dynamic: "Contract Signed Date Dynamic",
  status_comments: "Status Comments",
  collection_l_2: "Collection % (L-2)",
  collection_l: "Collection % (L)",
  month_year_l_2: "Month & Year (L-2)",
  month_year_l_2_dynamic: "Month & Year (L-2) Dynamic",
  month_year_l_1: "Month & Year (L-1)",
  month_year_l_1_dynamic: "Month & Year (L-1) Dynamic",
  amount_submitted_l_2: "Amount Submitted (L-2)",
  amount_submitted_l_1: "Amount Submitted (L-1)",
  amount_submitted: "Amount Submitted",
  amount_adjus: "Amount Adjusted",
  res_rate: "Resolution Rate",
  amount_col: "Amount Collected",
  mention_by: "Mentioned By",
  priority: "Priority",
  add_contact_details: "Add Contact Details",
  select: "Please Select",
  email: "Email",
  enter_number: "Enter Your Number",
  enter_email: "Enter Your Email",
  most_used_suggestion: "Most Used & Suggestion",
  address: "Address",
  zipCode: "Zip Code",
  state: "State",
  country: "Country",
  inv_number: "Invoice Number",
  inv_date: "Invoice Date",
  due_date: "Due Date",
  due_days: "Due Days",
  all_actions: "All Actions",
  co_inv_status: "Status",
  co_inv_amount: "Amount",
  inv_amount: "Invoice Amount",
  co_inv_ccy: "CCY",
  customer_summary: "Consumer Summary",
  confirm_message: "Confirmation Message",
  enable_confirm_for: "Enable Confirmation For",
  customer_overview: "Consumer Overview",
  adhoc_upload: "Adhoc Data Upload",
  communication_template: "Communication Template",
  workflow_maintenance: "Workflow Maintenance",
  cus_name: "Name",
  display_name: "Name",
  due_inv_count: "#Due",
  due_inv_amount: "Due Amt",
  overdue_inv_count: "#Overdue",
  overdue_inv_amount: "Overdue Amt",
  total_inv_count: "#Total",
  total_inv_amount: "Total Amt",
  last_action_date: "Last Action Date",
  last_action_name: "Last Action Name",
  last_action_comment: "Last Action Comment",
  next_action_date: "Next Action Date",
  sugg_next_action: "Suggested Next Action",
  workflow: "Workflow",
  rel_manager: "Relation Manager",
  max_payment_delay: "Max Payment Delay",
  avg_payment_delay: "Avg Payment Delay",
  last_response_date: "Last Response Date",
  days_after_last_response: "Days After Last Response",
  save_as_new_view: "Save As New View",
  add_view: "Add View",
  new_view: "New View",
  create: "Create",
  create_view: "Create View",
  cancel: "Cancel",
  default_view: "Default View",
  pinned_view: "Pinned View",
  enable_for_organization_users: "Enable for organization users",
  description: "Description",
  name: "Name",
  required: "Required",
  view_name: "View Name",
  template_type: "Template Type",
  template_sub_type: "Template Sub Type",
  view_description: "View Description",
  template_name: "Template Name",
  template_description: "Template Description",
  template_organization: "Template Organization",
  template_languages: "Template Languages",
  workflow_name: "Workflow Name",
  workflow_description: "Workflow Description",
  max_contact_in_month: "Max Contact in Month",
  workflow_average_delay: "Avg Delay",
  workflow_enter_a_tag: "Add #Tags",
  add_multiple_tags_by_pressing_enter: "Press enter after each # Tags",
  action_name: "Action Name",
  wf_max_contact_in_month: "Contact in Month",
  action_description: "Action Description",
  action_type: "Type",
  recipient_type: "Choose Recipient Type",
  choose_sms_template: "Choose Sms Template",
  choose_letter_template: "Choose Letter Template",
  choose_email_template: "Choose Email Template",
  sms_content_preview: "SMS Content Preview",
  subject_preview: "Subject Preview",
  email_content_preview: "Email Content Preview",
  instruction_to_user: "Instruction To User",
  postal_service: "Postal Service",
  postal_type: "Postal Type",
  letter_preview: "Letter Content Preview",
  last_modified: "Last Modified",
  most_used: "Most Used",
  all: "All",
  sms: "SMS",
  letter: "Letter",
  email: "Email",
  search_templates: "Search by Name or Description",
  add_template: "Add Template",
  rows_per_page: "Rows per page",
  prev: "Prev",
  next: "Next",
  min: "Min",
  max: "Max",
  search_here: "Search Here",
  date_month_year: "MM/DD/YYYY - MM/DD/YYYY",
  select_view: "Select View",
  // cp-action lang
  capture_call_details: "Capture Call Details",
  reciever_place: "Receiver",
  call_outcome: "Call Outcome",
  promise_to_pay: "Promise to Pay",
  PROMISE_TO_PAY: "Promise to Pay",
  CALL_BACK_LATER: "Call back Later",
  MEETING_REQUEST: "MEETING REQUEST",
  RECORD_DISPUTE: "RECORD DISPUTE",
  UNSUCCESSFUL: "UNSUCCESSFUL",
  call_back_later: "Call back Later",
  meeting_request: " Meeting Request",
  promise: "Promise",
  callback: "Call back Days",
  dispute: "Dispute",
  unsuccessful: "Unsuccessful",
  promise_amount: "Promise Amount",
  hold_workflow_review_promise_date: "Pause Workflow review Promise Date",
  send_confirmation_message: "Send Confirmation Message",

  promise_to_pay_confirmation: "Promise to Pay Confirmation",
  callback_confirmation: "Call Back Confirmation",
  meeting_confirmation: "Meeting Confirmation",
  add_notes_for_internal_reference_here:
    "Add notes for internal reference here (optional)... ",
  comments: "Comments or Remarks",
  comm_type: "Type",
  call_back_time: "Call Back Time",
  meeting_time: "Meeting Time",
  send_promise_follow_up_message: "Send Promise Follow up Message",
  send_callback_follow_up_message: "Send Call Back Follow up Message",
  send_meeting_follow_up_message: "Send Meeting Follow up Message",
  recipients: "Recipients",
  sms_content: "SMS Content",
  review_and_send_sms: "Review and Send SMS",
  review_and_send_letter: "Review and Send Letter",
  letter_remainder: "Letter Reminder",
  review_and_send_email: "Review and Send Email",
  cc1: "CC",
  bcc1: "BCC",
  cc: "Press enter after each Email",
  bcc: "Press enter after each Email",
  subject: "Subject",
  select_date: "Select Date",
  full_amount: "Full Amount",
  promise_type: "Promise Type",
  promise_date: "Promise Date",
  bru_date: "Bureau Date",
  planned_payment_amount: "Payment Amount",
  planned_installation_amount: "Installment amount",
  planned_start_date: "Start Date",
  planned_frequency: "Frequency",
  promise_to_follow_up_message: "Promise to Follow-up Message ",
  callback_follow_up_message: "Call Back Follow-up Message ",
  meeting_follow_up_message: "Meeting Follow-up Message ",
  call_back_date: "Call Back Date",
  meeting_date: "Meeting Date",
  hold_workflow_till_call_back_date: "Pause workflow till Call Back Date",
  hold_workflow_till_meeting_date: "Pause workflow till Meeting Date",
  remove_hold_till_meeting_date:
    "Remove Current hold & Apply new hold till Meeting Date",
  call_back_confirmation: "Call Back Confirmation",
  call_back_reminder_message: "Call Back reminder Message",
  user_comments_here: "User Comments Here",
  meeting_venue: "Meeting Venue",
  meeting_value: "Meeting Value",
  meeting_date: "Meeting Date",
  meeting_time: "Meeting Time",
  service_incomplete: "Service Incomplete",
  open: "Open",
  date_picker: "Date Picker",
  follow_up_call: "Follow-up Call",
  unsuccessful_reason: "Unsuccessful Reason",
  contact_unsuccessful: "Contact Unsuccessful",
  invoice_summary: "Invoice Summary",
  invoice_overview: "Invoice Overview",
  user: "Users",
  add_user: "Add User",
  payment_date: "Payment Date",
  payment_amount: "Payment Amount",
  payment_method: "Payment Method",
  payment_reference: "Payment Reference",
  payment_status: "Payment Status",
  expect_payment_status_date: "Expect Payment Status Date",
  user_notes: "User Notes",
  add_payments: "Add Payments",
  receiver: "Receiver",
  meeting_venue: "Meeting Venue",
  phone_type: "Phone Type",
  country_code: "Country Code",
  email_type: "Email Type",
  record_dispute: "Record Dispute",
  start_workflow_from: "Start Workflow From",
  note: "Note",
  take_a_note: "Take a Note",
  customer_workflow: "Consumer Workflow",
  all_customers: "All Consumers",
  customers: "Consumers",
  invoices: "Invoices",
  amount_to_Pay: "Amount To Pay",
  day_1: "Day 1",
  day_5: "Day 5",
  day_14: "Day 14",
  day_19: "Day 19",
  day_25: "Day 25",
  upload_file_columns: "Upload File Columns",
  first_row_data: "First Row Data",
  data_format: "Data Format",
  save_mapping_for_future_reference: "Save Mapping for Future Reference",
  mapping_with_system_fields: "Mapping with System Fields",
  to: "To",
  hold_reason: "Pause Reason",
  hold_action_till: "Pause Action Till",
  followup_date: "Follow Up Date",
  capture_dispute_details: "Capture Dispute Details",
  dispute_type: "Dispute Type",
  dispute_review_date: "Dispute Review Date",
  upload_field: "Upload File Columns",
  format: "Data Format",
  system_field: "Mapping with System Fields",
  group_contact: "Group Contact",
  default_column: "Default Column",
  purpose: "Purpose",
  default: "default",
  save_mapping_for_future_reference: "Save Mapping For Future Reference:",
  organization_name: "Organization Name",
  file_name: "File Name",
  uploaded_by: "Uploaded By",
  uploaded_at: "Uploaded at",
  stats: "Stats",
  status: "Status",
  user_profile: "User Profile",
  // invoices: "Default Value",
  upload: "Upload ID",
  organization: "Organization Name",
  z_name: "File Name",
  user: "Upload User",
  // date_&_time:"Date and Time"
  stats: "Upload Status",
  upload_from_system: "Upload From System",
  upload_attachments: "Upload Attachments",
  upload_drop_zone_caption: "Click here to upload or drag & drop",
  copy_template: "Copy Template",
  edit_template: "Edit Template",
  view_template: "View Template",
  delete_template: "Delete Template",
  template_disabled: "Template Disabled",
  template_enabled: "Template Enabled",
  workflow_disabled: "Workflow Disabled",
  workflow_enabled: "Workflow Enabled",
  phone_code: "Phone Code",
  start_date: "Start Date",
  date_range: "Date Range",
  log_in_time: "Log in Time",
  log_out_time: "Log out Time",
  ip_address: "IP Address",
  eg_password: "eg:password@123",
  account_currency: "Account Currency",
  user_maintenance: "User Maintenance",
  account_type: "Account Type",
  account_routing_no: "Account Routing No",
  account_no: "Account No",
  account_new1: "Account #",
  detail: "Detail",
  contact_person_name: "Contact Person Name",
  business_email: "Business Email ",
  next_action_name: "Next Action Name",
  unsucessful_reason: "Unsuccessful Reason",
  reference: "Reference",
  due_amount: "Due Amount",
  company_name: "Company Name",
  cus_currency: "Consumer Currency",
  promise_comment: "Promise Comment",

  address_line_2_area_and_locality_details: "Address Line 2",
  hold_workflow_till_follow_up_date: "Pause workflow till follow up date",
  view_contact_details: "View Contact Details",
  edit_contact_details: "Edit Contact Details",
  allocated_invoice_numbers: "Allocated Invoice Numbers",
  pending_allocation: "Pending Allocation",
  edit_user: "Edit User",

  /** NEEDS ACTION LABELS **/
  CUSTOMER_WITH_NO_CONTACTS: "Consumer with No Contacts",
  RELATION_MANAGER_NOT_ASSIGNED: "Relation Manager Not Assigned",
  WORKFLOW_NOT_ASSIGNED: "Workflow Not Assigned",
  ON_HOLD: "On-Pause Review",
  UNASSIGNED_CREDIT_NOTE: "Unassigned Credit Note",
  EXCESS_PAYMENTS: "Excess Payments",
  LE_MOT: "Motions Hearing",
  CALL_FOLLOWUP: "Call Follow-up",
  EMAIL_FOLLOWUP: "Email Follow-Up",
  SMS_FOLLOWUP: "SMS Follow-up",
  PENDING_PROMISE_REVIEWS: "Promise To Pay Review",
  PENDING_DISPUTE_REVIEWS: "Dispute Review",
  CREDIT_LIMIT_BREACHED: "Credit Limit Breached",
  CREDIT_LIMIT_WARNING: "Credit Limit Warning",
  is_active: "Active",
  is_2fa_setup: "2FA Setup",

  /** Consumer ACTION STAGE LABELS **/
  settled: "Settled",
  promise_in_place: "Promise In Place",
  active_disputes: "Active Disputes",
  followup_in_place: "Follow Up In Place",
  followup_delayed: "Follow Up Delayed",
  workflow_closed: "Workflow Closed",
  adhoc_follow_up: "Adhoc Follow Up",
  skip_trace: "Skip Trace",
  yet_to_initiate: "Yet To Initiate",
  payment_plan: "Payment Plan",
  SUCCESS_PAYMENT: "Success Payment",
  FAILED_PAYMENT: "Failed Payment",
  PORTAL_VISIT: "Portal Visit",
  // sms gateway
  account_sid: "Account SID",
  auth_token: "Auth Token",
  phone_number: "Phone Number",
  provider: "Provider",
  rate_min: "Rate",
  daily_limit: "Daily Limit",
  carrier: "Carrier",
  from: "From",

  // org settings
  date_format: "Date Format",
  amount_format: "Amount Format",
  currency_format: "Currency Format",
  amount__format: "Select Your Amount Format",
  date__format: "Select Your Date Format",
  stop_customer: "Stop Consumer Follow-ups when there is no Overdue Invoice",
  total_outstanding:
    "Start Consumer Follow-ups when Credit Limit Exceeds Total Outstanding",

  // pricing page lang
  choose_a_plan_tailored_to_your_needs: "Choose a Plan tailored to your needs",
  ready_to_get_started: "Ready To Get Started?",
  back_to_ricing: "Back To Pricing",
  annual_plan: "Annual Plan",
  monthly_plan: "Monthly Plan",
  skip_for_free_trail: "Skip for free trail",
  upgrade_to_continue: "Upgrade to Continue",
  free_trial_expired: "Free Trial Expired",
  search_by_inv_number: "Search by Invoice Number",
  select_the_method: "Select The Method",
  secret_key: "Secret Key",
  online: "Online",
  offline: "Offline",
  audit_by: "Audit By",
  ref_name: "Account Name",
  help_text: "Help Text",
  payment_mode: "Payment Mode",
  payment_gateway: "Payment Gateway",
  secret_key: "Secret Key",
  audit_on: "Audit On",
  logo: "Logo",
  instruction: "Instruction",
  report_analytics: "Report Analytics",
  customer_currency: "Consumer Currency",
  max_delay: "Max Delay",
  overdue_amount: "Overdue Amount",
  captured_on: "Captured On",
  "Captured On_dynamic": "Captured On Dynamic",
  action_comment: "Action Comment",
  template: "Template",
  action_outcome: "Action Outcome",
  promise_currency: "Promise Currency",
  promise_status: "Promise Status",
  disute_review_date: "Dispute Review Date",
  dispute_details: "Dispute Details",
  callback_date: "Callback Date",
  dispute_invoices: "Dispute Invoices",
  dispute_comment: "Dispute Comment",
  payment_currency: "Payment Currency",
  note_category: "Note Category",
  note_type: "Note Type",
  select_user: "Select User",
  select_report_by: "Filter Report By",
  allocated_to: "Allocated To",
  credit_limit_utilization: "Credit Limit Utilization",
  credit_limit_status: "Credit Limit Status",
  currentDue: "Current Due",
  b1: "1-30 Days",
  b2: "31-60 Days",
  b3: "61-90 Days",
  b4: "91 Days and Over",
  cna: "Consumer Name",
  mention_place: "Mentioned On",
  mention_at: "Mentioned At",
  notification_status: "Notification Status",
  action_due_date: "Action Due Date",
  ageing_summary_report: "Ageing Summary Report",
  aging_summary_report: "Aging Summary Report",
  user_mention_report: "User Mention Report",
  activity_report: "Activity Report",
  promise_to_pay_report: "Promise To Pay Report",
  dispute_report: "Dispute Report",
  payment_report: "Payment Report",
  note_report: "Notes Report",
  credit_limit_report: "Credit Limit Report",
  needs_action_report: "Needs Action Report",
  category: "Category",
  invoice: "Invoice",
  sub_category: "Sub Category",
  primary_contact: "Primary Contact",
  call_to_action: "Call To Action",
  footer: "Footer",
  phone_number_id: "Phone Number ID ",
  whatsapp_buisness_account_id: "Whatsapp Buisness Account ID",
  user_access_token: "User Access Token",
  review_and_send_whatsapp: "Review & Send Whatsapp",
  search_by_inv_number: "Search by inv Number",

  header_height: "Header Height",
  header_zoom: "Header Zoom",
  footer_height: "Footer Height",
  footer_zoom: "Footer Zoom",
  date_of_communication: "Date of Communication",
  delivery_method: "Delivery Method",
  tracking_url: "Tracking URL",
  delivery_status: "Delivery Status",
  audit_at: "Audit at",
  is_disabled: "Status",
  refetch_template: "Refetch Status",
  pricing_type: "Pricing Type",
  price_currency: "Price Currency",
  unit: "Unit",
  price: "Price",
  product_type: "Type",
  audit_date: "Audit Date",
  tax: "Tax",
  checkin_date: "Checkin Date",
  item: "Item",
  checkin_time: "Checkin Time",
  location: "Location",
  role_department: "Role & Dept",
  auth_type: "Auth Type",
  checkin_line_item: "Checkin Line Item",
  checkin_amount: "Checkin Amount",
  checkin_currency: "Checkin CCY",
  checkin_location: "Checkin Location",
  checkin_comment: "Checkin Comments",
  invoice_status: "Invoice Status",
  manual_status_user: "Manual Status User",
  recurring_invoice_currency: "Recurring Invoice Currency",
  invoice_name: "Recurring Invoice Name",
  skip_duration: "Skip Duration",
  total_line_item: "Total Line Item",
  sub_total: "Sub Total",
  discount_amount: "Discount",
  tax_amount: "Tax",
  paused: "Paused",
  paused_on: "Paused On",
  paused_by: "Paused By User",
  select_workflow: "Select Workflow",
  select_language: "Select Language",
  letter_name: "Letter Name",
  mapping_name: "Mapping Name",
  is_enabled: "Is Enabled",
  is_deleted: "Is Deleted",
  flat_fee_amount: "Flat fee Amount",
  admin_name: "Admin Name",
  auto_generate_invoice: "Auto Generate Invoice",
  last_billed_date: "Billed Date",
  last_billed_date_dynamic: "Billed Date Dynamic",
  last_billed_value: "Last Billed Value",
  add_revenue_and_billing: "Add Revenue & Billing",
  revenue_and_billing: "Revenue & Billing",
  pause_workflow_for_manual_recon: "Pause workflow for manual recon",
  user_location: "User Location",
  manager: "Manager",
  business_unit_name: "Client",
  business_unit_location: "Client Location",
  days_since_created: "DSC",
  action_stage: "Action Stage",
  action_stage_inv: "Action Stage/Last Tran Date",
  is_quick_action: "Is Paused",
  hold_quick_action_reason: "Pause Reason",
  hold_quick_action_till: "Pause Till Date",
  filters: "Filters",
  pause_reason: "Pause Reason",
  pause_action_on_this_customer_till: "Pause Action on this consumer till",
  pause_action_on_this_invoice_till: "Pause Action on this invoice till",
  flat_fee: "Flat Fee",
  last_billed_start_date: "Start Date",
  last_billed_end_date: "End Date",
  last_billed_due_amount: "Due Amount",
  last_billed_refund_amount: "Refund Amount",
  last_billed_client_payment: "Client Payment",
  last_billed_trust_payment: "Trust Payment",
  last_billed_by: "Billed By",
  commission_percentage: "Commission Percentage",
  created_file_id: "Created File ID",
  updated_file_id: "Updated File ID",
  customer_dob: "Consumer DOB",
  insurance: "Insurance",
  employer: "Employer",
  month_year_l_1: "Month & Year (L-1)",
  client_reference: "Client Reference",
  invoice_description: "Invoice Description",
  capture_notes_at: "Capture Notes at",
  map_based_on: "Map Based on",
  landline: "Landline",
  landline_type: "Landline Type",
  landline_code: "Landline Code",
  type_contact: "Type",
  mobile: "Mobile",
  search_anything: "Search Anything",
  search_by_customer_name: "Search by Cus Name",
  installment_date: "Installment Date",
  planned_installment_amount: "Planned Installment Amount",
  hold_workflow_till_planned_date:
    "Pause the workflow till the Payment Plan End Date",
  pause_workflow_till_follow_up_date: "Pause Workflow till follow-up date",
  follow_up_call: "Follow-Up Call",
  unsuccessful_reason: "Unsuccessful Reason",
  formula: "Formula",
  enter_formula: "Enter Formula",
  revenue_dashboard: "Revenue Dashboard",
  customer_stage: "Consumer Stage",
  allow_payment_receipt_download: "Allow Payment Receipt Download",
  portal_visit: "Portal Visit",
  portal_payment: "Portal Payment",
  portal_promise: "Portal Promise",
  portal_callback: "Portal Call Back",
  sms_reply: "Consumer SMS Reply",
  SMS_REPLY: "SMS Reply",
  email_reply: "Consumer Email Reply",
  whatsapp_reply: "WhatsApp Response",
  WHATSAPP_REPLY: "Whatsapp Reply",
  app_payment: "App Payment",
  customer_action_analysis: "Consumer Action Analysis",
  is_display_invoice_description: "Display Invoice Description",
  portal_failed_payment: "Portal Failed Payment",
  sms_status: "SMS Status",
  send_confirmation_message: "Send Confirmation Message",
  send_follow_up_message: "Send Follow Up Message",
  send_action_follow: "Send Payment Plan Reminder Message",
  installment_amount: "Installment Amount",
  last_action_date_dynamic: "Last Action Date Dynamic",
  last_customer_action: "Last Cus Action",
  next_action_date_dynamic: "Next Action Date Dynamic",
  last_action_outcome: "Last Action Outcome",
  last_customer_action_date: "Last Consumer Action Date",
  last_customer_action_date_dynamic: "Last Consumer Action Date Dynamic",
  invoice_date_dynamic: "Invoice Date Dynamic",
  due_date_dynamic: "Invoice Due Date Dynamic",
  last_customer_action_name: "Last Consumer Action Name",
  created_on_dynamic: "Created on Dynamic",
  choose_payment: "Choose Payment",
  save_card_information_for_future_purpose:
    "Save Card Information for future purpose",
  cur: "Cur",
  business_unit: "Client",
  my_team: "My Team",
  self: "Self",
  add_customer: "Add Consumer",
  download_customer_data: "Download Consumer Data",
  download_workflow_list_data: "Download Workflow List Data",
  download_relation_manager_list: "Download Relation Manager List",
  upload_data: "Upload Data",
  list_data: "List Data",
  dialer_data: "Dialer Data",
  field_visit_data: "Field Visit Data",
  needs_action: "Needs Action",
  customer_stage: "Consumer Stage",
  clear_filters: "Clear Filters",
  apply: "Apply",
  plus_add_filter: "+ Add Filter",
  quick_actions: "Quick Actions",
  hold_action_applied_till: "Hold Action Applied till",
  due_to: "due to",
  manual_call: "Manual Call",
  Call_Follow_up_capture_discussion_outcome:
    "Call Follow-up & capture discussion outcome",
  SMS_Reminders_with_without_an_actionable_link:
    "SMS Reminders with/without an actionable link",
  Email_follow_up_with_Customer: "Email follow-up with Consumer",
  next_action: "Next Action",
  Capture_your_planned_Next_action: "Capture your planned Next action",
  Communicate_and_connect_via_WhatsApp: "Communicate and connect via WhatsApp",
  Review_and_Update_Dispute_Status: "Review and Update Dispute Status.",
  pause_action: "Pause Action",
  Pause_Actions_and_Customer: "Pause Actions and Consumer",
  Capture_Payments_made_by_the_Customer:
    "Capture Payments made by the Consumer",
  Review_the_promise_provided_by_the_Customer:
    "Review the promise provided by the Consumer",
  Proceed_your_letter_Action_on_your_Own:
    "Proceed your letter Action on your Own",
  Change_your_status_of_updated_Letter: "Change your status of updated Letter",
  inv: "inv",
  total_amount: "Total Amount",
  unapplied_credit: "Unapplied Credit",
  in: "in",
  promise_reviews: "Promise Reviews",
  dispute_review: "Dispute Review",
  call_number_voice: "Call Number",
  message_id_voice: "Message Id",
  upload_voice_mail_status: "Upload Voice Mail Status",
  relation_manager: "Relation Manager",
  workFlow: "WorkFlow",
  assign_workFlow: "Assign WorkFlow",
  workFlow_on_hold: "WorkFlow on Hold",
  Business_unit_location: " Client & location",
  Tags: "Tags",
  Customer_Status: "Consumer_Status",
  OverDue: "OverDue",
  timeline: "Timeline",
  workflow_action: "Workflow Action",
  More_Info: "More Info",
  due: "Due",
  overdue: "Overdue",
  Search_Invoices: "Search Invoices",
  Invoice: "Invoice",
  Recurring: "Recurring",
  recurring: "Recurring",
  add_invoice: "Add Invoice",
  NEXT_ACTION: "Next Action",
  hold_action: "Hold Action",
  action_skipped: "Skip Action",
  whatsapp: "WhatsApp",
  INVOICE_UPDATE: "Invoice Update",
  Partially_Delivered_and_Read: "Partially Delivered and Read ",
  Partially_Delivered: " Partially Delivered",
  Bounced: "Bounced",
  Delivered: "Delivered",
  Read: "Read",
  User_Comments: "User Comments",
  Promise_Amount: " Promise Amount",
  Call_Unsuccessful: "Call : Unsuccessful",
  portal: "Portal",
  Call_Back_Later: "Call Back Later",
  logout: "Logout",
  user_information: "User Information",
  password_setting: "Password Setting",
  contact_phone: "Contact Phone",
  save_changes: "Save Changes",
  change_password_info:
    "Changing your password every 90 days helps it to be secure",
  reset_2fa_info: "Reset 2FA when you are in need",
  invalid_report_type: "Invalid Report Type",
  today: "Today",
  week_to_date: "Week to Date",
  month_to_date: "Month to Date",
  quarterly_to_date: "Quarterly to Date",
  half_yearly_to_date: "Half Yearly to Date",
  custom_date: "Custom Date",
  general_and_application_preference: "General and Application Preference",
  general_and_application_preference_description:
    "General information of the Organization and Application side Preference",
  contact_details: "Contact Details",
  contact_detail: "Contact Detail",
  contact_details_description: "Organization contact details",
  payment_details: "Payment Details",
  payment_details_description: "Setup Payment information for the Organization",
  accounting_books: "Accounting Books",
  accounting_books_description:
    "Premium - Get personalized SMS delivery number andb SMS response read option",
  sms_gateway: "SMS Gateway",
  sms_gateway_description:
    "Configure SMS Gateway Keys to perform SMS - based Follow-ups",
  outbound_email: "Outbound Email",
  outbound_email_description: "",
  cloud_telephony: "Cloud Telephony",
  cloud_telephony_description: "",
  whatsapp: "WhatsApp",
  customer_portal: "Consumer Portal",
  customer_portal_description:
    "Customize what information your consumer can view in the portal and respond",
  automation_settings: "Automation Settings",
  default_configuration: "Default Configuration",
  tax_rate_maintenance: "Tax Rate Maintenance",
  organization_details: "Organization Details",
  edit: "Edit",
  delete: "Delete",
  file_name: "File Name",
  file_type: "File Type",
  enter_comma: "Enter , | ; :",
  application_preference: "Application Preference",
  payment_details: "Payment Details",
  payment_details_description: "Setup Payment information for the Organization",
  add_payment_method: "Add Payment Method",
  payment_setting: "Payment Setting",
  payment_setting_add_payment: "Add Payment",
  payment_setting_add_payment_description:
    "When enabled user can also do add payment within the Consumer Payment option",
  payment_setting_auto_sync_payments: "Auto Sync Payments",
  payment_setting_auto_sync_payments_description:
    "When enabled, application will do auto sync of Payments added from Portal or Agent with Accounting Books",
  payment_setting_update_due_amount: "Update Due Amount",
  payment_setting_update_due_amount_description:
    "When enabled, Payments captured will reflect on the due amount automatically",
  payment_setting_auto_compute_promise_status: "Auto Compute Promise Status",
  payment_setting_auto_compute_promise_status_description:
    "When enabled,Promise Status will be computed automatically based on below status",
  assign_data: "Assign Data",
  credit_report: "Credit Report",
  generate_credit_report: "Generate Credit Report",
  upload_return_file: "Upload Return File",
  payments: "Payments",
  custom: "Custom",
  payment_setting_auto_compute_payment_plan_status:
    "Auto Compute Payment Plan Status",
  payment_setting_auto_compute_payment_plan_description:
    " When enabled,Payment Plan Status will be computed automatically based on below status.",
  review_promise: "Review Promise",
  letter_status: "Letter Status",
  residual_amount: "Residual Amount",
  pause_the_workflow_till_the_payment_plan_end_date:
    "Pause the workflow till the Payment Plan End Date",
  payment_plan_confirmation: "Payment Plan Confirmation",
  send_payment_plan_follow_up_message: "Payment Plan Follow Up Message",
  pause_workflow_till_payment_plan_end_date:
    "Pause the workflow till the Payment Plan End Date",
  release_promise_hold: "Release Promise Hold",
  payment_plan_template: "Payment Plan Template",
  release_workflow_patment_plan_final_installment_status_kept:
    "Release workflow when the Payment Plan Final Installment Status is Kept",
  release_workflow_patment_plan_final_installment_status_partial:
    "Release workflow when the Payment Plan Final Installment Status is Partial",
  release_workflow_patment_plan_final_installment_status_broken:
    "Release workflow when the Payment Plan Final Installment Status is Broken",
  release_workflow_patment_plan_final_installment_status_cancelled:
    "Release workflow when the Payment Plan Final Installment Status is Cancelled",
  update_customer_status: "Update Consumer Status",
  cancel_Payment_after: "Cancel Payment Plan After",
  payment_plan_confirmation: "Payment Plan Confirmation",
  send_payment_plan_reminder_message: "Send Payment Plan Reminder Message",
  PENDING_PAYMENT_PLAN_MANUAL_REVIEWS: "Pending Payment Plan Manual Review",
  promise_to_pay: "Promise to Pay",
  payment_plan: "Payment Plan",
  PENDING_PAYMENT_PLAN_AUTO_REVIEWS: "Pending Payment Plan Auto Review",
  PENDING_PROMISE_AUTO_REVIEWS: "Pending Promise Auto Review",
  PENDING_PROMISE_MANUAL_REVIEWS: "Pending Promise Manual Review",
  review_promise_type: "Type",
  update_PAYMENT_PLAN_status: "Update Payment Plan Status",
  update_PROMISE_status: "Update Payment Status",
  publishable_key: "Publishable Key",
  review_promise_amount: "Planned Amount",
  review_promise_date: "Planned Date",
  review_promise_comment: "Comments",
  CANCELED: "Cancelled",
  contact_validation: "Contact Validation",
  workflow_hold: "Pause Reason",
  workflow_hold_till: "Pause Till Date",
  broken: "Broken",
  canceled: "Canceled",
  in_progress: "In Progress",
  in_review: "In Review",
  kept: "Kept",
  partial: "Partial",
  payment_plan: "Payment Plan",
  update_PROMISE_TO_PAY_status: "Update Promise To Pay Status",
  primary_contact_status: "Prim Ctc Stats",
  esclation_contact_status: "Esca Ctc Stats",
  payment_plan_amount: "Payment Plan Amount",
  instalment_amount: "Installment Amount",
  payment_plan_frequency: "Payment Plan Frequency",
  payment_plan_start_date: "Payment Plan Start Date",
  payment_plan_end_date: "Payment Plan End Date",
  payment_plan_method: "Payment Plan Method",
  cur: "Cur",
  contact_status_maintenane: "Contact Status Maintenance",
  sticky_notes_maintenance: "Sticky Notes Maintenance",
  search_status_maintenane: "Search by Sticky Notes",
  sticky_notes: "Sticky Notes",
  Invoice_Item: "Invoice Item",
  Business_Unit_Maintenance: "Client Maintenance",
  Revenue_and_Billing: "Revenue and Billing",
  Communication_Template: "Communication Template",
  Workflow_Maintenance: "Workflow Maintenance",
  Organization_Settings: "Organization Settings",
  Role_Maintenance: "Role Maintenance",
  User_Maintenance: "User Maintenance",
  add_contact_status: "Add Contact Status",
  Type_of_Contact: "Type of Contact",
  Edit_Contact_Status: "Edit Contact Status",
  View_Contact_Status: "View Contact Status",
  Add_Contact_Status: "Add Contact Status",
  Contact_Details: "Contact Details",
  Use_Comments: "Use Comments",
  send_subscribe_confirmation: "Send Subscribe Confirmation",
  Contact_Status_Settings: "Contact Status Settings",
  unsubscribe_keywords: "Unsubscribe Keywords",
  unsubscribe_confirmation: "Unsubscribe Confirmation",
  confirmation_template: "Confirmation Template",
  Subscribe_Keywords: "Subscribe Keywords",
  Invalid_Keywords: "Invalid Keywords",
  Subscribe_Confirmation: "Subscribe Confirmation",
  Invalid_Confirmation: "Invalid Confirmation",
  add_charge: "Charge",
  charge_type: "Charge Type",
  business_dashboard: "Client Dashboard",
  closed: "Closed",
  frequency: "Frequency",
  max_lead_time: "Lead Time",
  discount: "% Discount",
  of_pay: "# of pay",
  last_billed_commission_amount: "Commission Amount",
  call: "Call",
  Call_Successful_Confirmation: "Call Successful Confirmation",
  is_hold: "Is Hold",
  hold_till: "Hold Till",
  hold_comment: "Hold Comment",
  hold_till_dynamic: "Hold Till Dynamic",
  custom_fields: "Custom Fields",
  report_maintanance: "Report Maintenance",
  download_setting: "Download Settings",
  subaccount_sid: "Sub Account SID",
  remove_the_existing_field:
    "Remove the existing field value when the mapped field value is Blank",
  subaccount_sid: "Subaccount SID",
  is_default_enabled_throttling: "Enabled Throttling",
  default_limit: "Default Limit",
  default_frequency: "Default Frequency",
  relation: "Relations",
  api_user_name: "API User Name",
  api_password: "API Password",
  credit: "Credit",
  contact: "Contact",
  captured_by_and_time: "Captured by And Time",
  debit_first_installment_now: "Debit First Installment now",
  enable_calendar_alert: "Enable Calendar Alert",
  enable_hsa: "Enable HSA",
  time: "Time",
  collection_l_1: "Collection % (L-1)",
  no_of_records_l: "# of Records (L)",

  amount_submitted_l: "Amount submitted (L)",
  client_data_upload: "Client Data Upload",
  adhoc_upload_file_type: "Adhoc Upload File Type",
  merchant_id: "Merchant ID",
  merchant_key: "Merchant key",
  profile_id: "Profile ID",
  business_short_code: "Business Short Code",
  pass_key: "Pass Key",
  consumer_key: "Consumer Key",
  consumer_secret: "Consumer Secret",
  admin_user_name: "Admin User Name",
  admin_user_password: "Admin User Password",
  username: "Username",
  portfolio_name: "Portfolio Name",
  encryption_key: "Encryption Key",
  target_url: "Target URL",
  web_service_username: "Web Service Username",
  web_service_password: "Web Service Password",
  web_service_zid: "Web Service Zid",
  report_api_id: "Report API ID",
  api_source_key: "API Source Key",
  tokenization_source_key: "Tokenization Source Key",
  letter_heading: "Letter Status Maintenance",
  letter_states: "Search By Consumer Name.ID,Inv,Letter ref#",
  delivery_method: "Delivery Method",
  delivery_status: "Delivery Status",
  letter_ref: "Letter Ref",
  communication_sent_date: "Communication Sent Date",
  update_by: "Update By",
  update_PAYMENT_PLAN_details: "Update Payment Plan Details",
  update_PROMISE_TO_PAY_details: "Update Promise To Pay Details",
  alert_time: "Alert Time",
  payment_plan_status: "Payment Plan Status",
  next_payment_amount: "Next Payment Amount",
  next_payment_date: "Next Payment Date",
  total_instalments_count: "Total Installments Count",
  complete_instalments_count: "Complete Installments Count",
  instalation_amount_to_date: "Instalation Amount to Date",
  payments_amount_to_date: "Payments Amount to Date",
  payment_plan_comment: "Payment Plan Comment",
  payment_plan_report: "Payment Plan Report",
  payment_plan_installment_report: "Payment Plan Installment Report",
  instalment_sl_no: "Installment SL No",
  instalment_date: "Installment Date",
  instalment_currency: "Installment Currency",
  instalment_status: "Installment Status",
  calendar_alert_time: "Alert Time",
  default_value: "Default Value",
  updated_based_on: "Updated Based On",
  primary_call: "Primary Call Status",
  primary_email: "Primary Email Status",
  primary_sms: "Primary SMS Status",
  primary_whatsapp: "Primary Whatsapp Status",
  client_ref: "Client Ref",
  created_upload_id: "Created Upload Id",
  updated_upload_id: "Updated Upload Id",
  capture_action_based_on_field_visit: "capture_action_based_on_field_visit",
  return_visit_date: "Visit Date",
  next_action_time: "Next Action Time",
  contact_for_notifications: "Contact for Notifications",
  visit_date: "Visit Date",
  visit_date_time: "Visit Date & Time",
  created_date_time: "Created Date & Time",
  visit_date_time_dynamic: "Visit Date Time Dynamic",
  visit_time: "Visit Time",
  visit_til: "Visit Locked Till",
  visit_user: "Visit User",
  return_visit_time: "Visit Time",
  send_field_visit_follow_up_message: "Send field visit follow up message",
  pause_workflow_till_field_visit_date: "Pause workflow till field visit date",
  field_visit_confirmation: "Field Visit Confirmation Message",
  field_visit_follow_up_message: "Field Visit Follow up Message",
  send_field_visit_promise_follow_up_message:
    "Send Field Visit Follow up Message",
  hold_workflow_till_field_visit_date: "Pause workflow till Field Visit Date",
  contact_for_notifications: "Contact for Notification",
  follow_up: "Follow-up",
  follow_up_time: "Select Time",
  custom_location: "Custom Location",
  contact_name: "Contact Name",
  address_city: "Address City",
  address_zip_code: "Zip Code",
  address_state: "Address State",
  address_country: "Address Country",
  capture_action_based_on_field_visit: "Capture Action Based on Field Visit",
  field_visit: "Field Visit",
  from_email_id_setup_for_batch: "From Email ID Setup For Batch",
  display_name_setup_for_email_id: "Display Name Setup For Email ID",
  from_email_id_setup_for_online: "From Email ID Setup For Online",
  is_use_user_email: "Use Agent Email ID for Online Email",
  is_keep_org_contact_email: "Keep Organization Contact Email ID in CC",
  is_keep_user_email: "Keep User Email ID in CC",
  reminders: "Reminders",
  display_names_org: "Display Name",
  sms_analytics: "SMS Analytics",
  email_analytics: "Email Analytics",
  command_center: "Command Center",
  workflow_based: "Workflow Based",
  is_sms: "SMS",
  is_whatsapp: "WhatsApp",
  is_call: "Call",
  is_email: "Email",
  subscription_status: "Subscription Status",
  call_status: "Call Status",
  whatsapp_status: "Whatsapp Status",
  email_status: "Email Status",
  next_action_auto: "Next Action Auto",
  collection_dashboard: "Collection Dashboard",
  send_unsubscribe_confirmation: "Send Unsubscribe Confirmation",
  value: "Value",
  dummy: "-",
  skip_trace_providers: "Configure your Skip Trace Providers",
  skip_trace_setup: "Skip Trace Setup",
  add_provider: "Add Provider",
  galaxy_ap_name_label: "Galaxy ap Name Label",
  galaxy_ap_name_placeholder: "Galaxy ap Name Placeholder",
  galaxy_ap_password_label: "Galaxy ap Password Label",
  galaxy_ap_password_placeholder: "Galaxy ap Password Placeholder",
  is_lead_sms_auto: "Disable Auto-SMS for Leads",
  is_lead_email_auto: "Disable Auto-Email for Leads",
  is_lead_letter_auto: "Disable Auto-Letter for Leads",
  is_lead_whatsapp_auto: "Disable Auto-WhatsApp for Leads",
  galaxy_ap_name: "Galaxy AP Name",
  galaxy_ap_password: "Galaxy AP Password",
  customer_ssn: "Consumer SSN",
  address_city: "Address City",
  address_state: "Address State",
  address_zip_code: "Address Zip Code",
  customer_country: "Consumer Country",
  exclude_days: "Exclude Days",
  IN_PROGRESS: "InProgress",
  LEAD_FOUND: "LeadFound",
  FAILED: "Failed",
  INVALID: "Invalid",
  NEW: "New",
  skip_trace_report: "Skip Trace Report",
  skip_trace_provider: "Provider",
  skip_trace_request_fields: "Request Field as one line",
  skip_trace_status: "Skip Trace Status",
  no_of_phone_lead: "Number of Phone Lead",
  phone_leads: "Lead Phone Numbers",
  no_of_email_lead: "Number of Email Lead",
  email_leads: "Lead Email Address",
  no_of_address_lead: "Number of Address Lead",
  address_leads: "Lead Address",
  communication_sent_date_dynamic: "Communication Sent Date Dynamic",
  edit_charge: "Edit Charge",
  NO_LEAD: "No Lead",
  LEAD_FOUND: "Lead Found",
  IN_PROGRESS: "In-Progress",
  skip_trace_date: "Skip Trace Date",
  skip_trace_date_dynamic: "Skip Trace Date Dynamic",
  skip_trace_count: "Skip Trace Count",
  skip_trace_status: "Skip Trace Status",
  pause_workflow_till_field_visit_date: "Pause workflow till Field Visit Date",
  LEAD_VERIFICATION_PENDING: "Lead Verification Pending",
  FIELD_VISIT: "Field Visit",
  CANCELED_FIELD_VISIT: "Canceled Field Visit",
  PENDING_FIELD_VISIT: "Field Visits Review",
  type_your_location: "Type your Location",
  primary_contact: "Primary Contact",
  reason: "Reason (Optional)",
  LETTER_FOLLOWUP: "Letter Followup",
  PENDING_FIELD_VISIT: "Pending Field Visit",
  your_location: "Your Location",
  OPEN: "Open",
  CLOSED: "Closed",
  PENDING_FIELD_VISITS: "Pending Field Visits",
  IN_REVIEW: "In Review",
  reference_balance: "Reference Balance",
  last_action_summary_1: "Last Action Summary 1",
  last_action_summary_2: "Last Action Summary 2",
  last_action_summary_3: "Last Action Summary 3",
  last_action_summary_4: "Last Action Summary 4",
  last_action_summary_5: "Last Action Summary 5",
  last_action_detail_1: "Last Action Detail 1",
  last_action_detail_2: "Last Action Detail 2",
  last_action_detail_3: "Last Action Detail 3",
  last_action_detail_4: "Last Action Detail 4",
  last_action_detail_5: "Last Action Detail 5",
  skip_trace_count: "Skip Trace Count",
  skip_trace_status: "Skip Trace Status",
  skip_trace_date: "Skip Trace Date",
  assign_to: "Assign to",
  req_comments: "Comments",
  capture_details: "Capture Details",
  holiday_blackout_maintenance: "Holiday Blackout Maintenance",
  add_holiday_blackout_maintenance: "Add Holiday Blackout",
  edit_holiday_blackout_maintenance: "Edit Holiday Blackout",
  view_holiday_blackout_maintenance: "View Holiday Blackout",
  days_for_followup: "Days For Follow-up",
  type_holiday: "Type",
  title: "Title",
  date_from_to: "Date From - To",
  serial_no: "Serial #",
  mini_miranda_configuration: "Mini-Miranda Configuration",
  consumer_status_maintenance: " Consumer Status Maintenance",
  bankruptcy_maintenance: "Bankruptcy Maintenance",
  status_maintenance: "Status Maintenance",
  mini_miranda: "Mini Miranda",
  ent_rul_name: "Enter Rule Name",
  rule_desc: "Rule Description",
  mini_miranda_add: "Add Mini Miranda",
  debtor_rights: "Consumer Rights",
  agent_instruction: "Agent Instruction",
  weekly_counter: "Weekly Counter",
  monthly_counter: "Monthly Counter",
  select_state: "Select State",
  select_country: "Select Country",
  country_and_state: "Country and State",
  weekly_follow_up: "Weekly Follow up",
  monthly_follow_up: "Monthly Follow up",
  audit_date_time: "Audit Date and Time",
  no_of_cl_login: "# of Client Login",
  last_login_date: "Last Login Date",
  last_modified_by: "Last Modified By",
  last_modified_on: "Last Modified On",
  last_modified_on_dynamic: "Last Modified On Dynamic",
  enable_disable_flag: "Enable / Disable Flag",
  is_display_agent_instruction:
    "Display Agent Instruction on Consumer Overview Load",
  max_weekly_follow_up: "Weekly Follow up",
  max_monthly_follow_up: "Monthly Follow up",
  is_enable: "Status",
  mini_miranda_EDIT_MM: "Edit Mini Miranda",
  mini_miranda_VIEW_MM: "View Mini Miranda",
  serial_number: "Serial #",
  CLARIFICATION_REQUEST_INFO: "Clarification",
  OPEN: "Open",
  CLARIFICATION: "Clarification",
  dispute_amount: "Dispute Amount",
  DOCUMENT_REQUEST_INFO: "Document",
  SUBMITTED: "Submitted",
  In_progress: "In Progress",
  request_information: "Pending Information",
  request_information_title: "Request Information",
  customer_name_client_ref: "Consumer Name & Client Ref",
  raised_by_raised_on: "Raised by & Raised On",
  category_sub_category: "Category & Sub Category",
  RE_OPEN: "Re-Opened",
  with_team_assigned_to: "With Team & Assigned To",
  status_review_date: "Status & Review Date",
  response_by_response_on: "Response By & Response On",
  assigned_to: "Assigned To",
  USER_REQ_INFO_DOCUMENT: "Request Information Document",
  USER_REQ_INFO_CLARIFICATION: "Request Information Clarification",
  REQUEST_INFO_DOCUMENT: "Request Information Document",
  REQUEST_INFO_CLARIFICATION: "Request Information Clarification",
  confirmation_message: "Confirmation Message",
  follow_up_message: "Follow Up Message",
  inbound_call: "Inbound Call",
  increment_count: "Increment Count",
  call_back_consent:
    "Received consumer consent for Call Back Later On {reminderOn} & within {withIn} Days",
  dispute_consent:
    "Received consumer consent to provide dispute update over Call",
  received_consent:
    "Received Consumer consent for Reminder Call on {reminderOn} & within {withIn} Days",
  call_validation_duration: "Call - Validation Duration",
  max_call_attempts: "Call - Maximum Attempts",
  max_call_rpc: "Call - Maximum RPC",
  sync: "Sync",
  pending: "Pending",
  allocation: "Allocation",
  allocation_inv: "Allocated Inv",
  amount_date: "Amount & Date",
  hold_workflow_till_PROMISE_TO_PAY: "Pause workflow till Promise Date",
  hold_workflow_till_CALL_BACK_LATER: "Pause workflow till Call Back Date",
  hold_workflow_till_RECORD_DISPUTE: "Pause Workflow till Dispute date",
  hold_workflow_till_FIELD_VISIT: "Pause workflow till Field Visit Date",
  hold_workflow_till_UNSUCCESSFUL: "Pause workflow till follow up date",
  hold_workflow_till_PAYMENT_PLAN:
    "Pause the workflow till the Payment Plan End Date",
  hold_workflow_till_ADPP:
    "Pause the workflow till the Auto Payment Plan End Date",

  remove_hold_till_PROMISE_TO_PAY:
    "Remove Current hold & Apply new hold till Promise Date",
  remove_hold_till_CALL_BACK_LATER:
    "Remove Current hold & Apply new hold till Call Back Date",
  remove_hold_till_RECORD_DISPUTE:
    "Remove Current hold & Apply new hold till Dispute date",
  remove_hold_till_FIELD_VISIT:
    "Remove Current hold & Apply new hold till Field Visit Date",
  remove_hold_till_PAYMENT_PLAN:
    "Remove Current hold & Apply new hold till the Payment Plan End Date",
  remove_hold_till_ADPP:
    "Remove Current hold & Apply new hold till Auto Payment Plan End Date",
  remove_hold_till_NOTES:
    "Remove Current hold & Apply new hold till Follow up date",
  choose_source: "Choose Source",
  remove_hold_till_UNSUCCESSFUL:
    "Remove Current hold & Apply new hold till Follow up date",
  remove_hold_till_UNSUCCESSFULL:
    "Remove Current hold & Apply new hold till Follow up date",
  hold_workflow_till_NOTES: "Pause workflow till follow up date",

  location_tracking: "Location Tracker",
  PENDING_NOTES_REVIEWS: "Notes Review",
  customer_ref: "Consumer ID",
  search_customer: "Search Consumer",
  sol: "Statute of Limitation(in Months)",
  ilc: "Licensed",
  client_name_id: "Client Name & ID",
  client_name: "Client Name",
  inv_no_date: "Inv No & Date",
  inv_amount: "Inv Amount",
  due_amount: "Due Amount",
  attorney_name: "Attorney Name",
  firm_name: "Firm Name",
  no_of_installment: "# Installment",
  hold_workflow_till_UNSUCCESSFULL: "Pause workflow till follow up date",
  client_id_client_name: "Client / Ref ID",
  invoice_num_inv_date: "Inv Amnt / Date",
  due_amount_due_date: "Due Amnt / Date",
  due_days_debt: "Due days / Debt Status",
  type_your_source: "Type Your Source",
  type_address: "Type Your address",
  choose_address: "Choose Address",
  choose_phone_number: "Choose Phone Number",
  choose_email: "Choose Email",
  notes_client: "Client Notes",
  client_name: "Client Name",
  call_rpc_count: "Rpc Count",
  call_attempt_count: "Attempt Count",
  monthly_count: "Monthly Count",
  weekly_count: "Weekly Count",
  consent_date: "Consent Till Date",
  inbound_outbound: "Inbound/Outbound",
  breach_status: "Breach Status",
  whatsapp: "WhatsApp",
  PENDING_CONSUMER_REVIEWS: "Review Grouping",
  sec: "Section",
  aby: "Audit By",
  aat: "Audit On",
  add: "Debts Added",
  rem: "Debts Removed",
  legal_motion_report: "Legal Motion Report",
  legal_timesheet_report: "Legal Timesheet Report",
  legal_summary_report: "Legal Summary Report",
  legal_debt_report: "Legal Debt Report",
  debt_amount: "Debt Amount",
  status_date: "Status Date",
  client_approval_dt: "Client Approval dt",
  placement_dt: "Placement dt",
  our_counsel: "Our Counsel",
  our_counsel_firm: "Our Counsel Firm",
  opp_counsel: "Opp Counsel",
  opp_counsel_firm: "Opp Counsel Firm",
  court_number: "Court Number",
  court_area: "Court Area",
  legal_selected_date: "Legal Selected Date",
  complaint_filed_date: "Complaint Filed Date",
  sol_breach_status: "Sol Breach Status",
  user_id: "User Id",
  mention_user: "Mentioned User",
  total_time: "Total Time",
  updated_by: "Updated By",
  updated_at: "Updated At",
  legal_type: "Legal Type",
  created_at: "Created At",
  enter_the_months: "Enter the Months",
  type_your_comments: "Type your comments",
  method_and_to: "Method/To",
  type_your_address: "Type Address",
  customer_ref: "Consumer ID",
  suggestion_based: "Suggestion Based On",
  main_customer_details: "Main Consumer Details",
  review_customer_details: "Review Consumer Details",
  review_grouping_report: "Review Grouping Report",
  captured_date: "Captured Date",
  instalation_amount: "Installment Amount",
  kpi_maintenance: "KPI Maintenance",
  payment_collection_amount_target: "Payment Collected",
  commission_amount_target: "Commission Amount %",
  modified_by: "Audit User",
  modified_at: "Date & Time",
  payment_amount: "Payment Amount",
  commission_amount: "Commission Amount",
  performance_dashboard: "Performance Dashboard",
  dashboard_kpis: "Dash",
  enter_check_no: "Enter Check no",
  check_template: "Check Template",
  check_cleared_date: "Check Cleared Date",
  client_display_name: "Client Display Name",
  master_record: "Master Record",
  default_workflow: "Default Workflow",
  default_queue: "Default Queue",
  trust_client_account: "Trust Account Name",
  check_cleared_date: "Check Cleared Date",
  enter_check_no: "Enter Check Number",
  check_template: "Template",
  check_printed_date: "Check Printed Date",
  check_cleared_date: "Check Cleared Date",
  trust_account_name: "Trust Account Name",
  check_no: "Check #",
  work_queue: "Work Queue",
  default_access: "Default Access",
  access_type: "Access Type",
  payment_priority_billing: "Payment Priority & Billing",
  recover_from: "Recover From",
  work_queue: "Work Queue",
  ptp: "Promise To Pay",
  ppl: "Payment Plan",
  whs: "WhatsApp",
  audby: "Audit By",
  audat: "Audit On",
  pcat: "Payment Allocated",
  camt: "Commission Amount",
  county: "County",
  business_unit: "Client",
  invoice_number: "Invoice Number",
  invoice_date: "Invoice Date",
  invoice_due_date: "Due Date",
  invoice_description: "Invoice Description",
  not_paid_amount: "Total",
  status: "Status",
  wq: "Work Queue",
  ssn: "SSN",
  dob: "DOB",
  bankruptcy: "Bankruptcy #",
  court: "Court",
  chapter: "Chapter",
  dist_court_name: "District Court Name",
  address_court: "Address of the Court",
  judge_name: "Judge Name",
  amount: "Amount",
  date_field: "Date Filed",
  attorney_info: "Select",
  date_field_range: "Date Filed Range",
  notify_date: "Notification Date",
  hearing_date: "Hearing Date",
  dismiss_date: "Dismissal Date",
  discharge_date: "Discharge Date",
  bankruptcy_date: "Bankruptcy Date",
  failed_date: "Failed Date",
  reaffirmation: "Reaffirmation",
  converted: "Converted",
  attorney_firm: "Attorney Firm",
  attroney_address: "Address",
  attroney_phone: "Phone",
  attroney_email: "Attorney Email",
  trustee_name: "Trustee Name",
  trustee_firm: "Trustee Firm",
  trustee_phone: "Phone",
  trustee_email: "Trustee Email",
  full_name: "Full Name",
  docker_number: "Docket Number",
  status_of_filling: "Status of Filing",
  audit_by_on: "Audit By & On",
  area_code: "Area Code",
  role_access: "Role Access",
  payment_collection_expected: "Payment Collected &  Expected",
  commission_earned: "Commission Earned",
  report_download: "Report Download",
  client: "Client",
  generated_by: "Generated By",
  generated_at: "Generated On",
  user_confirmation: "User Confirmation",
  bankruptcy_information: "Bankruptcy Information",
  add_bankruptcy: "Add Bankruptcy",
  bankruptcy_form: "Bankruptcy Form",
  audit_at: "Audit On",
  fildt: "Date Filed",
  notidt: "Notification Date",
  herdt: "Hearing Date",
  dimidt: "Dismissal Date",
  dichdt: "Discharge Date",
  bkdt: "Bankruptcy Date",
  faildt: "Failed Date",
  docno: "Docket Number",
  adln1: "Address Line 1",
  adln2: "Address Line 2",
  zpcd: "Zip Code",
  reaff: "Reaffirmation",
  conv: "Converted",
  stofi: "Status of filinng",
  atref: "Attorney Ref",
  atna: "Attorney Name",
  atfirm: "Attormy firm",
  bkamt: "Amount",
  dscotna: "District Court Name",
  adocot: " Address Court",
  judna: "Judge Name",
  reaff: "Reaffirmation",
  conv: "Converted",
  docno: "Docket Number",
  stofi: "Status of Filing",
  atna: "Attorney Name",
  li1: "Address Line 1",
  li2: "Address Line 2",
  cit: "City",
  sta: "State",
  cou: "Country",
  zip: "Zip Code",
  trstn: "Trustee Name",
  trstfr: "Trustee Firm",
  fnam: "First Name",
  lnam: "Last Name",
  ctry: "Country",
  bkno: "Bankruptcy",
  bkchp: "Chapter",
  coc: "Phone Code",
  val: "Phone Number",
  user_manual_and_support: "User manual and Support",
  client_access_email: "Client Access Email",
  internal_users_email: "Internal Users Email",
  internal_users: "Internal Users",
  client_access: "Client Access",
  user_manual_link: "User Manual Link",
  support_mail_id: "Support Email ID",
  legal_status: "Legal Status",
  status: "Status",
  legal_action_stage: "Legal Action Stage",
  refund_method: "Refund Method",
  client_number: "Client Number",
  INVA_ADJ: "Invoice Adjustment",
  select_bureaus: "Select Bureaus",
  creditor_classfication: "Select Business Type",
  minimum_balance: "Minimum Balance",
  rm_assign_at: "RM Assign at",
  rm_assign_at_dynamic: "RM Assign at Dynamic",
  nacha_file_configuration: "NACHA File Configuration",
  configure_the_default_values_for_nacha_file_generation:
    "Configure the default values for NACHA file generation",
  nacha_file_config: "NACHA File Config",
  rdfi_routing_number: "RDFI - Routing Number",
  processing_priority: "Processing Priority",
  identification_number: "Identification Number",
  entry_description: "Entry Description",
  odfi_routing_number: "ODFI - Routing Number",
  check_number: "Check Number",
  payment_description: "Payment Description",
  check_maintenance: "NACHA Maintenance",
  batch_no: "Batch #",
  created_date: "Created Date",
  no_of_record: "# of Record",
  credit_amount: "Credit Amount",
  debit_amount: "Debit Amount",
  processing_status: "Processing Status",
  effective_entry_date: "Effective Entry Date",
  comp_descriptive_date: "Company Descriptive Date",
  search_cus_name_check_no: "Search By Consumer Name,Check #",
  check_amount: "Check Amount",
  routing_no: "Routing #",
  name_in_the_check: "Name in the Check",
  allocated_invoice_no: "Allocated Invoice #",
  trace_number: "Trace Number",
  captured_at: "Captured At",
  relationship_manager: "Relationship Manager",
  captured_at_dynamic: "Captured At Dynamic",
  payment_date_dynamic: "Payment Date Dynamic",
  "Payment Date_dynamic": "Payment Date Dynamic",
  letter_vendor: "Letter Vendor",
  configure_your_letter_vendor: "Configure your Letter Vendor",
  vendor: "Vendor",
  client_id: "Client ID",
  company_name: "Company Name",
  contact_name: "Contact Name",
  contact_ph_num: "Contact Phone Number",
  server_add: "Server Address",
  port: "Port",
  user_id: "User ID",
  u_f_t: "Upload File Time",
  r_f_t: "Response File Time",
  u_f_p: "Upload File Path",
  r_f_p: "Response File Path",

  group_id: "Group ID",
  is_last_manual_action_taken_by_rm: "Last User Action RM",
  last_manual_action_user: "Last User Action By",
  last_user_action_date: "Last User Action Date",
  last_user_action: "Last User Action",
  last_user_action_date_dynamic: "Last User Action Date Dynamic",
  sms_unsuccessful: "SMS Unsuccessful Count",
  sms_sucessfull: "SMS Successful Count",
  email_unsuccessful: "Email Unsuccessful Count",
  email_sucessfull: "Email Successful Count",
  whap_unsuccessful: "WhatsApp Unsuccessful Count",
  whap_sucessfull: "WhatsApp Successful Count",
  call_unsuccessful: "Call Unsuccessful Count",
  call_sucessfull: "Call Successful Count",
  ltr_unsuccessful: "Letter Unsuccessful Count",
  ltr_sucessfull: "Letter Successful Count",
  itemization: "Itemization",
  our_reference: "Our Reference",
  client_reference: "Client Reference",
  itemization_date: "Itemization Date",
  due_date: "Due Date",
  des: "Description",
  principle_amt_woc: "Principal Amount",
  principal_amout_wc: "Principal Amount",
  client_woc_charges: "Client Charges",
  client_wc_chargesClient: "Client Charges",
  clien_interest_woc: "Client Interest",
  clien_interest_wc: "Client Interest",
  prior_payment: "Prior Payments",
  prior_adjustment: "Prior Adjustment",
  collection_fees: "Collection Fees",
  inv_amnt: "Invoice Amount",
  inv_date: "Invoice Date",
  p_amt: " Amount Placed",
  fea: "Fee Amount",
  int_amt: "Interest Amount",
  total_payment_amount: "Payment Amount",
  adj_amt: " Adjustment Amount",
  orgiamt: " Amount Placed",
  fea_amt: " Fee Amount",
  inv_int_amt: " Interest Amount",
  tpymt: " Payment Amount",
  adj_amnt: " Adjustment Amount",
  itmzn_date: " Itemization Date",
  itmzn_date_dynamic: " Itemization Date Dynamic",
  princple_amount: " Amount Placed",
  portal_success_payment: "Portal Success Payment",
  rel_manager_date: "Relation Manager Date",
  rel_manager_date_dynamic: "Relation Manager Date Dynamic",
  closed_consumer_status: "Consumer status for Closed state",
  active_consumer_status: "Consumer Status for Active state",
  //Maintain Mapping Adhoc
  CUSTOMER_INVOICE: "Consumer Invoice",
  CUSTOMER_CONTACT: "Consumer Contact",
  PAYMENT: "Payment & Adjustment",
  NOTES: "Notes",
  PREDEFINED: "Predefined Format",
  PD_PY_WD: "Payment & Adjustment",
  search_mapping_name: "Search By Mapping Name",
  last_call_suc_date_dynamic: "Last Call RPC Date Dynamic",
  last_call_suc_date: "Last Call RPC Date",
  primary_address: "Primary address Status",
  consumer_name: "Consumer Name",
  adjustment_date: "Adjustment Date",
  adjustment_amount: "Adjustment Amount",
  adjustment_type: "Adjustment Type",
  adjustment_reference: "Adjustment Reference",
  adjustment_description: "Adjustment Description",
  deleted: "Deleted",
  deleted_by: "Deleted By",
  deleted_on: "Deleted On",
  upload_file_id: "Upload File ID",
  adjustment_report: "Adjustment Report",
  updated_by_updated_at: "Updated Info",
  updated_at_dynamic: "Updated At Dynamic",
  customer_dob_dynamic: "Consumer DOB Dynamic",
  is_adhoc: "Is Adhoc",
  last_rpc_medium: "Last RPC Medium",
  last_rpc_date: "Last RPC Date",
  last_rpc_date_dynamic: "Last RPC Date Dynamic",
  raised_by: "Raised By",
  raised_on: "Raised On",
  response_by: "Response By",
  response_date: "Response Date",
  type_amount: "Type Amount",
  "workflow.name": "Workflow Name",
  inv_due_days: "Invoice Due Days",
  tpyamt: "Total Payments",
  payment_collection: "Payment Collected",
  payment_expected: "Payment Expected",
  FINAL_TEXT: "Final Text Message",
  TEMPLATE_VARIABLE: "Template Variable",
  max_settlement_rate: "Max Settlement Rate",
  discount_slab: "Discount Slab",
  p_discount: "% Discount",
  end_duration_in_days: "End Duration (in Days)",
  start_duration_in_days: "Start Duration (in Days)",
  discount_slab_name: "Discount Slab Name",
  replicate_client_from: "Replicate From (Client)",
  un_subscribe_report: "Communication Unsubscribe Report",
  rm_ass_at: "RM Allocation Date",
  rm_ass_at_dynamic: "RM Allocation Date Dynamic",
  // dspr: "DSP",
  commission_amount_included_mapped:
    "Commission amount included in the Adjustment amount mapped",
  dspr: "DSC-R",
  // client_secret: "Client Secret",
  // jwt_token: "JWT Token",
  p_iscount: "100-{{max_settlement_rate}}-0",

  strategy_maintenance: "Strategy Maintenance",
  add_strategy: "Add Strategy",
  view_strategy: "View Strategy",
  delete_strategy: "Delete Strategy",
  strategy_enabled: "Strategy Enabled",
  copy_strategy: "Copy Strategy",
  edit_strategy: "Edit Strategy",
  condition_name: "Condition Name",
  condition_description: "Condition Description",
  default_workflow_sgy: "Default Workflow Strategy",
  lst_ltr_dt: "Last Letter Date",
  lst_ltr_dt_dynamic: "Last Letter Date Dynamic",
  is_auto_payment_plan: "Auto Payment Plan",
  adc_upd_dt: "Updated Date",
  adc_upd_dt_dynamic: "Updated Date Dynamic",
  created_date: "Created Date",
  created_date_dynamic: "Created Date Dynamic",
  select_workflow_sgy: "Select Workflow Strategy",
  workflow_stragtegy: "Workflow Strategy",
  workflow_strategy_date: "Workflow Strategy Date",
  workflow_strategy_date_dynamic: "Workflow Strategy Date Dynamic",
  app_ppl: "App Payment Plan",
  portal_ppl: "Portal Payment Plan",
  group_name: "Group Name",
  select_groups: "Select Groups",
  payment_plan_info: "Payment Plan Information",
  pay_now_info: "Pay Now Information",
  edit_report: "Edit Report",
  delete_report: "Delete Report",
  view_report: "View Report",
  report_enabled: "Report Enabled",
  report_disabled: "Report Disabled",
  copy_report: "Copy Report",
  last_action_type: "Last Action Type",
  payment_type_method: "Payment Method",
  card_last_4_digit: "Card Last 4 Digit",
  card_type: "Card Type",
  last_action_type: "Last Action Type",
  payment_type_method: "Payment Method",
  portal_link: "Portal Link",
  format: "Format",
  master_record: "Master Record",
  record_created_date: "Record Created Date",
  record_created_date_dynamic: "Record Created Date Dynamic",
  report_delivery_settings: "Report Delivery Settings",
  report_description: "Report Description",
  client_grouping: "Client / Grouping",
  next_exec_time: "Next Execution Date & Time",
  destination_dtl: "Destination Details",
  select_report: "Select Report",
  select_client: "Select Client",
  next_excution_date: "Next Excution Date",
  server: "Server",
  upload_file_path: "Upload File Path",
  amount_date_status: "Date & Status",
  consolidated_action_remarks: "Consolidated Action Remarks",
  last_payment_amount: "Last Payment Amount",
  last_payment_date: "Last Payment Date",
  last_payment_date_dynamic: "Last Payment Date Dynamic",
  last_tran_date: "Last Tran Date",
  display_text: "Display Text",
  trans_code: "Trans Code",
  consumer_authentication: "Consumer Authentication",
  customize_consumer_overview: "Customize Consumer Overview",
  setup_consumer_level_authentication_parameters:
    "Setup Consumer Level Authentication Parameters",
  max_settlement_rate: "Max Settlement Rate",
  item_details: "Item Details",
  import_new_numbers: "Import New Numbers",
  paste_phone_number_below: "Paste Phone Number Below",
  phone_status: "Phone Status",
  paste_phone_number: "Paste Phone Number",
  new_numbers: "New Numbers",
  select_contact: "Select Contact",
  import_new_emails: "Import New Emails",
  paste_email_below: "Paste Email Below",
  lst_email_dt: "Last Email Date",
  lst_sms_dt: "Last SMS Date",
  lst_email_dt_dynamic: "Last Email Date Dynamic",
  lst_sms_dt_dynamic: "Last SMS Date Dynamic",
  sign_method: "E-Signature Method",
  expiry_date: "Expiry Date",
  custom_text: "Custom Text",
  e_signature_template: "E-Signature Template",
  event: "Event",
  e_sign_report: "E-Signature report",
  select_editor: "Select Editor",
  e_signature_report: "E-Signature report",
  ex_dt: "E-Signature Date",
  sgn_dt: "E-Signature Date",
  cat: "Created Date",
  fee_amt: "Processing Fee",
  total_paid_amount: "Total Amount",
  acc_last_4_digit: "Card Last 4 Digit",
  commission_ceiling_amount: "Commission Ceiling Amount",
  field_category: "Field Category",
  field_section: "Field Section",
  field_position: "Field Position",
  field_name: "Field Name",
  field_desc: "Field Desc",
  field_data_type: "Field Data Type",
  field_sub_type: "Field Sub Type",
  field_sample_value: "Field Sample Value",
  field_placeholder_text: "Field Placeholder Text",
  cus_position: "Position",
  position: "Position",
  data_type: "Data Type",
  sub_type: "Sub Type",
  custom_field_maintenance: "Custom Field Maintenance",
  sample_value: "Sample Value",
  path: "Path",
  placeholder_text: "Placeholder Text",
  is_editable: "Editable",
  is_hidden: "Hidden",
  is_genterated: "Genterated",
  cby: "Created By",
  idb: "Enable",
  udy: "Update By",
  udt: "Update At",
  unq_id: "Unique Identifier",
  unallocate_amount: "Excess Amount",
  voice_bot: "Voice Bot",
  invoice_view: "Invoice View",
  customer_view: "Consumer View",
  access_level: "Access Level",
  collection_target: "Collection Target",
  waive_off: "Waive Fee",
  primary_voice_bot: "Primary Voice Bot",
  waive_fee: "Waive Fee",
  search_type_code: "Search Type Code",
  noca_option: "NCOA Option",
  coa_option: "COA Option",
  phn_option: "Phone Option",
  src_typ_cd: "Search Type Code",
  noca_opt: "NCOA Option",
  coa_opt: "COA Option",
  phn_opt: "Phone Option",
  is_default: "Default",
  prf_id: "Profile Id",
  disable_needs_action: "Disable Needs Action for Lead Verification",
  captured_on_time: "Captured Time",
  zipcode: "Zipcode",
  customer_statusand_sub_status: "Status",
  customer_auto_status: "Action Stage",
  discount_amounts: "Discount Amount",
  disc_amt: "Discount Amount",
  "Updated At_dynamic": "Updated At Dynamic",
  disc_pert: "Discount Percentage",
  contact_download: "Contact Download",
  OLD: "Old",
  select_the_worklist: "Select Worklist",
  worklist_date: "Worklist Date",
  assign_worklist_to: "Assign Worklist To",
  worklist: "Worklist",
  search_by_name: "Search by Name",
  sort_by_worklist: "Sort Worklist By",
  worklist_name: "Worklist Name",
  ct_dob: "Contact DOB",
  ct_ssn: "Contact SSN",
};
