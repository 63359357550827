import React, { useState, useEffect, useContext, useMemo } from "react";

import _ from "lodash";
import "react-quill/dist/quill.snow.css";

import { SelectBox } from "../../components/SelectBox";
import { Icon, Typography } from "../../components_v2";
import {
  ADD_CONTACT_DRAWER,
  BULK_PHONE_IMPORT,
  EDIT_CONTACT,
  setDrawerState,
} from "../../redux/actions";
import { useDeleteCustomerContact } from "../../services/customerOverviewContacts";
import { useParams } from "react-router";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ModalContext } from "../../providers/ModalProvider";
import Warning from "../../assets/img/warning.png";
import { FormattedTime } from "react-intl";
import moment from "moment";
import queryClient from "../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../constants";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  ADD_CONTACTS,
  EDIT_CONTACTS,
  DELETE_CONTACTS,
} from "../../providers/RBACProvider/permissionList";
import useFormat from "../../hooks/useFormat";
import { PLACEMENT, StatefulTooltip, TRIGGER_TYPE } from "baseui/tooltip";
import { parsePhoneNumberFromString } from "libphonenumber-js";
const ContactDetails = ({
  contact = {},
  setDrawer = () => {},
  mutateAsync = () => {},
}) => {
  const {
    phone = [],
    landline = [],
    address = [],
    email = [],
    custom_field = {},
  } = contact;

  const { customerId } = useParams();
  const [showPhoneDetailsPersonal, setShowPhoneDetailsPersonal] =
    useState(false);
  const [showPhoneDetailsOther, setShowPhoneDetailsOther] = useState(false);
  const [showPhoneDetailsOffice, setShowPhoneDetailsOffice] = useState(false);
  const [showlandDetailsPersonal, setShowlandDetailsPersonal] = useState(false);
  const [showlandDetailsOther, setShowlandDetailsOther] = useState(false);
  const [showlandDetailsOffice, setShowlandDetailsOffice] = useState(false);

  const [showEmailDetails, setShowEmailDetails] = useState(false);
  const [showAddressDetails, setShowAddressDetails] = useState(false);
  let { currentOrganization } = useContext(MaxyfiContext);
  const { handleModal } = useContext(ModalContext);
  const format = useFormat();

  const { validPhone, validEmail, validLandline, validAddress } =
    useMemo(() => {
      let validPhone = phone?.sort((a, b) => (a.status === "VALID" ? -1 : 1));
      let validEmail = email?.sort((a, b) => (a.status === "VALID" ? -1 : 1));
      let validLandline = landline?.sort((a, b) =>
        a.status === "VALID" ? -1 : 1
      );
      let validAddress = address?.sort((a, b) =>
        a.status === "VALID" ? -1 : 1
      );
      return { validPhone, validEmail, validLandline, validAddress };
    }, [contact, phone, email, address, landline]);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const commentPhoneExists = phone.some(
    (phone) => _.get(phone, "comment", "").trim() !== ""
  );

  const commentLandlineExists = landline.some(
    (landline) => _.get(landline, "comment", "").trim() !== ""
  );

  const commentAddressExists = address.some(
    (address) => _.get(address, "comment", "").trim() !== ""
  );

  const commentEmailExists = email.some(
    (email) => _.get(email, "comment", "").trim() !== ""
  );

  if (!contact || !contact._id) {
    return <></>;
  }
  const ValidPhoneNumberFormat = parsePhoneNumberFromString(
    `${validPhone[0]?.country_code}${validPhone[0]?.value}`
  );
  let formattedNumber =
    ValidPhoneNumberFormat && ValidPhoneNumberFormat?.formatInternational();

  const ValidLandlineFormat = parsePhoneNumberFromString(
    `${validLandline[0]?.country_code}${validLandline[0]?.value}`
  );
  let formattedLandline =
    ValidPhoneNumberFormat && ValidLandlineFormat?.formatInternational();

  function formatNumber(formattedNumber) {
    const firstSpaceIndex = formattedNumber?.indexOf(" ");

    // Split the formatted number into two parts
    const firstPart = formattedNumber.substring(0, firstSpaceIndex + 1); // +1 to include the space
    const restPart = formattedNumber.substring(firstSpaceIndex + 1);

    // Replace spaces with hyphens in the rest part
    const restPartWithHyphens = restPart.replace(/ /g, "-");

    // Combine the first part and the modified rest part
    return firstPart + restPartWithHyphens;
  }

  //format SSN
  function formatSSN(ssn, fallback = "-") {
    if (!ssn || ssn.length !== 9 || isNaN(ssn)) {
      return fallback;
    }

    return `${ssn?.substring(0, 3)}-${ssn?.substring(3, 5)}-${ssn?.substring(
      5
    )}`;
  }

  // Toggle between showing and hiding the details

  const formattedSSN =
    custom_field && custom_field.ssn ? formatSSN(custom_field.ssn, "") : "-";

  const phoneTypePriority = {
    PERSONAL_MOBILE: 1,
    OFFICE_MOBILE: 2,
    OTHER_MOBILE: 3,
  };
  const landlineTypePriority = {
    HOME_LANDLINE: 1,
    OFFICE_LANDLINE: 2,
    OTHER_LANDLINE: 3,
  };
  // Sort validPhone array based on the phone type priority
  const sortedPhones = validPhone.sort((a, b) => {
    const typeA = phoneTypePriority[a.type] || 4; // default to 4 if type not found
    const typeB = phoneTypePriority[b.type] || 4;
    return typeA - typeB;
  });
  const sortedLandline = validLandline.sort((a, b) => {
    const typeA = landlineTypePriority[a.type] || 4; // default to 4 if type not found
    const typeB = landlineTypePriority[b.type] || 4;
    return typeA - typeB;
  });

  //type based map
  const typeBasedPhonePersonal =
    Array.isArray(sortedPhones) &&
    sortedPhones.length > 0 &&
    sortedPhones.filter((per) => per.type === "PERSONAL_MOBILE");

  const typeBasedPhoneOffice =
    Array.isArray(sortedPhones) &&
    sortedPhones.length > 0 &&
    sortedPhones.filter((per) => per.type === "OFFICE_MOBILE");
  const typeBasedOther =
    Array.isArray(sortedPhones) &&
    sortedPhones.length > 0 &&
    sortedPhones.filter((per) => per.type === "OTHER_MOBILE");
  const typeBasedLandlinePersonal =
    Array.isArray(sortedLandline) &&
    sortedLandline.length > 0 &&
    sortedLandline.filter((per) => per.type === "HOME_LANDLINE");
  const typeBasedLandlineOther =
    Array.isArray(sortedLandline) &&
    sortedLandline.length > 0 &&
    sortedLandline.filter((per) => per.type === "OTHER_LANDLINE");
  //
  const typeBasedLandlineOffice =
    Array.isArray(sortedLandline) &&
    sortedLandline.length > 0 &&
    sortedLandline.filter((per) => per.type === "OFFICE_LANDLINE");

  let statusValue = ["INVALID", "DNC"];

  console.log("typeBasedOther", typeBasedOther);

  return (
    <div className="co-contact-card_content">
      {typeBasedPhonePersonal &&
      typeBasedPhonePersonal[0] &&
      !showPhoneDetailsPersonal ? (
        <div className="co-contact-card_element">
          <Icon icon="user_outLine" color="#ADADAD" />

          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
            className={
              statusValue.includes(typeBasedPhonePersonal[0]?.status)
                ? "text-secondary"
                : ""
            }
          >
            {parsePhoneNumberFromString(
              `${typeBasedPhonePersonal[0]?.country_code}${typeBasedPhonePersonal[0]?.value}`
            )
              ? parsePhoneNumberFromString(
                  `${typeBasedPhonePersonal[0]?.country_code}${typeBasedPhonePersonal[0]?.value}`
                ).formatInternational()
              : "-"}{" "}
            {typeBasedPhonePersonal && typeBasedPhonePersonal.length > 1 ? (
              <Typography
                type="p"
                subType="regular"
                className="mx-chip"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowPhoneDetailsPersonal(true);
                }}
              >
                +{typeBasedPhonePersonal.length - 1}
              </Typography>
            ) : (
              <></>
            )}
          </Typography>

          <Icon
            icon="phone_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedPhonePersonal[0]?.status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedPhonePersonal?.[0]?.call_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="sms_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedPhonePersonal[0]?.status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedPhonePersonal?.[0]?.sms_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="whatsapp_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedPhonePersonal[0]?.status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedPhonePersonal?.[0]?.whatsapp_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="voice_bot_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedPhonePersonal[0]?.status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedPhonePersonal?.[0]?.ac_sts === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          {_.get(typeBasedPhonePersonal, "[0].comment", "").trim() !== "" && (
            <StatefulTooltip
              accessibilityType={"tooltip"}
              content={
                <div
                  style={{
                    minWidth: "auto",
                    maxWidth: "300px",
                    minHeight: "auto",
                    maxHeight: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3, // Limits to 3 lines
                    WebkitBoxOrient: "vertical",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  {_.get(typeBasedPhonePersonal, "[0].comment", "")}
                </div>
              }
            >
              <div>
                <Icon icon="contact_chat_icon" color="#ADADAD" />
              </div>
            </StatefulTooltip>
          )}
        </div>
      ) : (
        <>
          {Array.isArray(typeBasedPhonePersonal) &&
            typeBasedPhonePersonal.length > 0 &&
            typeBasedPhonePersonal.map((item) => {
              let ValidPhoneNumberFormats = parsePhoneNumberFromString(
                `${item?.country_code}${item?.value}`
              );
              let formattedNumbers =
                ValidPhoneNumberFormats &&
                ValidPhoneNumberFormats?.formatInternational();

              return (
                <div className="co-contact-card_element">
                  {item?.type === "OTHER_MOBILE" ? (
                    <Icon icon="call_plus_outline" color="#ADADAD" />
                  ) : item?.type === "OFFICE_MOBILE" ? (
                    <Icon icon="suite_case_outline" color="#ADADAD" />
                  ) : item?.type === "PERSONAL_MOBILE" ? (
                    <Icon icon="user_outLine" color="#ADADAD" />
                  ) : (
                    <></>
                  )}
                  <Typography
                    type="p"
                    subType="regular"
                    className={
                      statusValue.includes(item.status) ? "text-secondary" : ""
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    {formattedNumbers ? formatNumber(formattedNumbers) : "-"}{" "}
                  </Typography>

                  <Icon
                    icon="phone_status"
                    size={18}
                    className={`${
                      statusValue.includes(item.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.call_status === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />
                  <Icon
                    icon="sms_status"
                    size={18}
                    className={`${
                      statusValue.includes(item.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.sms_status === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />
                  <Icon
                    icon="whatsapp_status"
                    size={18}
                    className={`${
                      statusValue.includes(item.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.whatsapp_status === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />
                  <Icon
                    icon="voice_bot_status"
                    size={18}
                    className={`${
                      statusValue.includes(item.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.ac_sts === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />
                  {item?.comment && (
                    <StatefulTooltip
                      accessibilityType={"tooltip"}
                      content={
                        <div
                          style={{
                            minWidth: "auto",
                            maxWidth: "300px",
                            minHeight: "auto",
                            maxHeight: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3, // Limits to 3 lines
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {_.get(item, "comment", "")}
                        </div>
                      }
                    >
                      <div>
                        <Icon icon="contact_chat_icon" color="#ADADAD" />
                      </div>
                    </StatefulTooltip>
                  )}
                </div>
              );
            })}
        </>
      )}

      {typeBasedOther && typeBasedOther[0] && !showPhoneDetailsOther ? (
        <div className="co-contact-card_element">
          <Icon icon="call_plus_outline" color="#ADADAD" />

          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
            className={
              statusValue.includes(typeBasedOther?.[0]?.status)
                ? "text-secondary"
                : ""
            }
          >
            {parsePhoneNumberFromString(
              `${typeBasedOther[0]?.country_code}${typeBasedOther[0]?.value}`
            )
              ? parsePhoneNumberFromString(
                  `${typeBasedOther[0]?.country_code}${typeBasedOther[0]?.value}`
                ).formatInternational()
              : "-"}{" "}
            {typeBasedOther && typeBasedOther.length > 1 ? (
              <Typography
                type="p"
                subType="regular"
                className="mx-chip"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowPhoneDetailsOther(true);
                }}
              >
                +{typeBasedOther.length - 1}
              </Typography>
            ) : (
              <></>
            )}
          </Typography>

          <Icon
            icon="phone_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedOther?.[0]?.status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedOther?.[0]?.call_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="sms_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedOther?.[0]?.status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedOther?.[0]?.sms_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="whatsapp_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedOther?.[0]?.status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedOther?.[0]?.whatsapp_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />

          <Icon
            icon="voice_bot_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedOther?.[0]?.status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedOther?.[0]?.ac_sts === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          {_.get(typeBasedOther, "[0].comment", "").trim() !== "" && (
            <StatefulTooltip
              accessibilityType={"tooltip"}
              content={
                <div
                  style={{
                    minWidth: "auto",
                    maxWidth: "300px",
                    minHeight: "auto",
                    maxHeight: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3, // Limits to 3 lines
                    WebkitBoxOrient: "vertical",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  {_.get(typeBasedOther, "[0].comment", "")}
                </div>
              }
            >
              <div>
                <Icon icon="contact_chat_icon" color="#ADADAD" />
              </div>
            </StatefulTooltip>
          )}
        </div>
      ) : (
        <>
          {Array.isArray(typeBasedOther) &&
            typeBasedOther.length > 0 &&
            typeBasedOther.map((item) => {
              let ValidPhoneNumberFormats = parsePhoneNumberFromString(
                `${item?.country_code}${item?.value}`
              );
              let formattedNumbers =
                ValidPhoneNumberFormats &&
                ValidPhoneNumberFormats?.formatInternational();

              return (
                <div className="co-contact-card_element">
                  <Icon icon="call_plus_outline" color="#ADADAD" />

                  <Typography
                    type="p"
                    subType="regular"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                    className={
                      statusValue.includes(item?.status) ? "text-secondary" : ""
                    }
                  >
                    {formattedNumbers ? formatNumber(formattedNumbers) : "-"}{" "}
                  </Typography>

                  <Icon
                    icon="phone_status"
                    size={18}
                    className={`${
                      statusValue.includes(item?.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.call_status === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />
                  <Icon
                    icon="sms_status"
                    size={18}
                    className={`${
                      statusValue.includes(item?.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.sms_status === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />
                  <Icon
                    icon="whatsapp_status"
                    size={18}
                    className={`${
                      statusValue.includes(item?.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.whatsapp_status === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />

                  <Icon
                    icon="voice_bot_status"
                    size={18}
                    className={`${
                      statusValue.includes(item?.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.ac_sts === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />
                  {item?.comment && (
                    <StatefulTooltip
                      accessibilityType={"tooltip"}
                      content={
                        <div
                          style={{
                            minWidth: "auto",
                            maxWidth: "300px",
                            minHeight: "auto",
                            maxHeight: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3, // Limits to 3 lines
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {_.get(item, "comment", "")}
                        </div>
                      }
                    >
                      <div>
                        <Icon icon="contact_chat_icon" color="#ADADAD" />
                      </div>
                    </StatefulTooltip>
                  )}
                </div>
              );
            })}
        </>
      )}

      {typeBasedPhoneOffice &&
      typeBasedPhoneOffice[0] &&
      !showPhoneDetailsOffice ? (
        <div className="co-contact-card_element">
          <Icon icon="suite_case_outline" color="#ADADAD" />

          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
            className={
              statusValue.includes(typeBasedPhoneOffice[0].status)
                ? "text-secondary"
                : ""
            }
          >
            {parsePhoneNumberFromString(
              `${typeBasedPhoneOffice[0]?.country_code}${typeBasedPhoneOffice[0]?.value}`
            )
              ? parsePhoneNumberFromString(
                  `${typeBasedPhoneOffice[0]?.country_code}${typeBasedPhoneOffice[0]?.value}`
                ).formatInternational()
              : "-"}{" "}
            {typeBasedPhoneOffice && typeBasedPhoneOffice.length > 1 ? (
              <Typography
                type="p"
                subType="regular"
                className="mx-chip"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowPhoneDetailsOffice(true);
                }}
              >
                +{typeBasedPhoneOffice.length - 1}
              </Typography>
            ) : (
              <></>
            )}
          </Typography>

          <Icon
            icon="phone_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedPhoneOffice[0].status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedPhoneOffice?.[0]?.call_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="sms_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedPhoneOffice[0].status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedPhoneOffice?.[0]?.sms_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="whatsapp_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedPhoneOffice[0].status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedPhoneOffice?.[0]?.whatsapp_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />

          <Icon
            icon="voice_bot_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedPhoneOffice[0].status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedPhoneOffice?.[0]?.ac_sts === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          {_.get(typeBasedPhoneOffice, "[0].comment", "").trim() !== "" && (
            <StatefulTooltip
              accessibilityType={"tooltip"}
              content={
                <div
                  style={{
                    minWidth: "auto",
                    maxWidth: "300px",
                    minHeight: "auto",
                    maxHeight: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3, // Limits to 3 lines
                    WebkitBoxOrient: "vertical",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  {_.get(typeBasedPhoneOffice, "[0].comment", "")}
                </div>
              }
            >
              <div>
                <Icon icon="contact_chat_icon" color="#ADADAD" />
              </div>
            </StatefulTooltip>
          )}
        </div>
      ) : (
        <>
          {Array.isArray(typeBasedPhoneOffice) &&
            typeBasedPhoneOffice.length > 0 &&
            typeBasedPhoneOffice.map((item) => {
              let ValidPhoneNumberFormats = parsePhoneNumberFromString(
                `${item?.country_code}${item?.value}`
              );
              let formattedNumbers =
                ValidPhoneNumberFormats &&
                ValidPhoneNumberFormats?.formatInternational();

              return (
                <div className="co-contact-card_element">
                  {item?.type === "OTHER_MOBILE" ? (
                    <Icon icon="call_plus_outline" color="#ADADAD" />
                  ) : item?.type === "OFFICE_MOBILE" ? (
                    <Icon icon="suite_case_outline" color="#ADADAD" />
                  ) : item?.type === "PERSONAL_MOBILE" ? (
                    <Icon icon="user_outLine" color="#ADADAD" />
                  ) : (
                    <></>
                  )}
                  <Typography
                    type="p"
                    subType="regular"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                    className={
                      statusValue.includes(item.status) ? "text-secondary" : ""
                    }
                  >
                    {formattedNumbers ? formatNumber(formattedNumbers) : "-"}{" "}
                  </Typography>

                  <Icon
                    icon="phone_status"
                    size={18}
                    className={`${
                      statusValue.includes(item.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.call_status === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />
                  <Icon
                    icon="sms_status"
                    size={18}
                    className={`${
                      statusValue.includes(item.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.sms_status === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />
                  <Icon
                    icon="whatsapp_status"
                    size={18}
                    className={`${
                      statusValue.includes(item.status)
                        ? "mx-icon--path-fill-disabled"
                        : item?.whatsapp_status === "VALID"
                        ? "mx-icon--path-fill-success"
                        : "mx-icon--path-fill-error"
                    }`}
                  />
                  {item?.comment && (
                    <StatefulTooltip
                      accessibilityType={"tooltip"}
                      content={
                        <div
                          style={{
                            minWidth: "auto",
                            maxWidth: "300px",
                            minHeight: "auto",
                            maxHeight: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3, // Limits to 3 lines
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {_.get(item, "comment", "")}
                        </div>
                      }
                    >
                      <div>
                        <Icon icon="contact_chat_icon" color="#ADADAD" />
                      </div>
                    </StatefulTooltip>
                  )}
                </div>
              );
            })}
        </>
      )}

      {typeBasedLandlinePersonal &&
      typeBasedLandlinePersonal[0] &&
      !showlandDetailsPersonal ? (
        <div className="co-contact-card_element">
          {typeBasedLandlinePersonal[0]?.type === "OTHER_LANDLINE" ? (
            <Icon icon="call_plus_outline" color="#ADADAD" />
          ) : typeBasedLandlinePersonal[0]?.type === "OFFICE_LANDLINE" ? (
            <Icon icon="suite_case_outline" color="#ADADAD" />
          ) : typeBasedLandlinePersonal[0]?.type === "HOME_LANDLINE" ? (
            <Icon icon="user_outLine" color="#ADADAD" />
          ) : (
            <></>
          )}
          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
            className={
              statusValue.includes(typeBasedLandlinePersonal[0].status)
                ? "text-secondary"
                : ""
            }
          >
            {parsePhoneNumberFromString(
              `${typeBasedLandlinePersonal[0]?.country_code}${typeBasedLandlinePersonal[0]?.value}`
            )
              ? parsePhoneNumberFromString(
                  `${typeBasedLandlinePersonal[0]?.country_code}${typeBasedLandlinePersonal[0]?.value}`
                ).formatInternational()
              : "-"}{" "}
            {_.get(typeBasedLandlinePersonal, "", []) &&
            typeBasedLandlinePersonal.length > 1 ? (
              <Typography
                type="p"
                subType="regular"
                className="mx-chip"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowlandDetailsPersonal(true);
                }}
              >
                +{typeBasedLandlinePersonal.length}
              </Typography>
            ) : (
              <></>
            )}{" "}
            {/* {_.get(landline[0], "comment", "") */}
          </Typography>

          <Icon
            icon="landline_outline"
            size={20}
            className={`${
              statusValue.includes(typeBasedLandlinePersonal[0].status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedLandlinePersonal?.[0]?.call_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="voice_bot_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedLandlinePersonal[0].status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedLandlinePersonal?.[0]?.ac_sts === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          {commentLandlineExists && (
            <StatefulTooltip
              accessibilityType={"tooltip"}
              content={
                <div
                  style={{
                    minWidth: "auto",
                    maxWidth: "300px",
                    minHeight: "auto",
                    maxHeight: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3, // Limits to 3 lines
                    WebkitBoxOrient: "vertical",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  {_.get(landline, "[0].comment", "").trim()}
                </div>
              }
            >
              <div>
                <Icon icon="contact_chat_icon" color="#ADADAD" />
              </div>
            </StatefulTooltip>
          )}
        </div>
      ) : (
        <>
          {Array.isArray(typeBasedLandlinePersonal) &&
            typeBasedLandlinePersonal.length > 0 &&
            typeBasedLandlinePersonal.map((item) => {
              let ValidLandNumberFormats = parsePhoneNumberFromString(
                `${item?.country_code}${item?.value}`
              );
              let formattedLandNumbers =
                ValidLandNumberFormats &&
                ValidLandNumberFormats?.formatInternational();

              return (
                <div className="co-contact-card_element">
                  <Icon icon="user_outLine" color="#ADADAD" />

                  <Typography
                    type="p"
                    subType="regular"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                    className={
                      statusValue.includes(item.status) ? "text-secondary" : ""
                    }
                  >
                    {formattedLandNumbers
                      ? formatNumber(formattedLandNumbers)
                      : "-"}{" "}
                  </Typography>

                  {item.call_status && (
                    <Icon
                      icon="landline_outline"
                      size={20}
                      className={`${
                        statusValue.includes(item.status)
                          ? "mx-icon--path-fill-disabled"
                          : item.call_status === "VALID"
                          ? "mx-icon--path-fill-success"
                          : "mx-icon--path-fill-error"
                      }`}
                    />
                  )}
                  {item?.comment && (
                    <StatefulTooltip
                      accessibilityType={"tooltip"}
                      content={
                        <div
                          style={{
                            minWidth: "auto",
                            maxWidth: "300px",
                            minHeight: "auto",
                            maxHeight: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3, // Limits to 3 lines
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {_.get(item, "comment", "")}
                        </div>
                      }
                    >
                      <div>
                        <Icon icon="contact_chat_icon" color="#ADADAD" />
                      </div>
                    </StatefulTooltip>
                  )}
                </div>
              );
            })}
        </>
      )}

      {typeBasedLandlineOther &&
      typeBasedLandlineOther[0] &&
      !showlandDetailsOther ? (
        <div className="co-contact-card_element">
          <Icon icon="call_plus_outline" color="#ADADAD" />

          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
            className={
              statusValue.includes(typeBasedLandlineOther[0].status)
                ? "text-secondary"
                : ""
            }
          >
            {parsePhoneNumberFromString(
              `${typeBasedLandlineOther[0]?.country_code}${typeBasedLandlineOther[0]?.value}`
            )
              ? parsePhoneNumberFromString(
                  `${typeBasedLandlineOther[0]?.country_code}${typeBasedLandlineOther[0]?.value}`
                ).formatInternational()
              : "-"}{" "}
            {_.get(typeBasedLandlineOther, "", []) &&
            typeBasedLandlineOther.length > 1 ? (
              <Typography
                type="p"
                subType="regular"
                className="mx-chip"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowlandDetailsOther(true);
                }}
              >
                +{typeBasedLandlineOther.length}
              </Typography>
            ) : (
              <></>
            )}{" "}
            {/* {_.get(landline[0], "comment", "") */}
            {commentLandlineExists && (
              <StatefulTooltip
                accessibilityType={"tooltip"}
                content={
                  <div
                    style={{
                      minWidth: "auto",
                      maxWidth: "300px",
                      minHeight: "auto",
                      maxHeight: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 3, // Limits to 3 lines
                      WebkitBoxOrient: "vertical",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {_.get(landline[0], "comment", "")}
                  </div>
                }
              >
                <div>
                  <Icon icon="contact_chat_icon" color="#ADADAD" />
                </div>
              </StatefulTooltip>
            )}{" "}
          </Typography>

          <Icon
            icon="landline_outline"
            size={20}
            className={`${
              statusValue.includes(typeBasedLandlineOther[0].status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedLandlineOther?.[0]?.call_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="voice_bot_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedLandlineOther[0].status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedLandlineOther?.[0]?.ac_sts === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
        </div>
      ) : (
        <>
          {Array.isArray(typeBasedLandlineOther) &&
            typeBasedLandlineOther.length > 0 &&
            typeBasedLandlineOther.map((item) => {
              let ValidLandNumberFormats = parsePhoneNumberFromString(
                `${item?.country_code}${item?.value}`
              );
              let formattedLandNumbers =
                ValidLandNumberFormats &&
                ValidLandNumberFormats?.formatInternational();

              return (
                <div className="co-contact-card_element">
                  <Icon icon="call_plus_outline" color="#ADADAD" />

                  <Typography
                    type="p"
                    subType="regular"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                    className={
                      statusValue.includes(item.status) ? "text-secondary" : ""
                    }
                  >
                    {formattedLandNumbers
                      ? formatNumber(formattedLandNumbers)
                      : "-"}{" "}
                  </Typography>

                  {item.call_status && (
                    <Icon
                      icon="landline_outline"
                      size={20}
                      className={`${
                        statusValue.includes(item.status)
                          ? "mx-icon--path-fill-disabled"
                          : item.call_status === "VALID"
                          ? "mx-icon--path-fill-success"
                          : "mx-icon--path-fill-error"
                      }`}
                    />
                  )}
                  {item.ac_sts && (
                    <Icon
                      icon="voice_bot_status"
                      size={18}
                      className={`${
                        statusValue.includes(item.status)
                          ? "mx-icon--path-fill-disabled"
                          : item.ac_sts === "VALID"
                          ? "mx-icon--path-fill-success"
                          : "mx-icon--path-fill-error"
                      }`}
                    />
                  )}
                  {item?.comment && (
                    <StatefulTooltip
                      accessibilityType={"tooltip"}
                      content={
                        <div
                          style={{
                            minWidth: "auto",
                            maxWidth: "300px",
                            minHeight: "auto",
                            maxHeight: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3, // Limits to 3 lines
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {_.get(item, "comment", "")}
                        </div>
                      }
                    >
                      <div>
                        <Icon icon="contact_chat_icon" color="#ADADAD" />
                      </div>
                    </StatefulTooltip>
                  )}
                </div>
              );
            })}
        </>
      )}

      {typeBasedLandlineOffice &&
      typeBasedLandlineOffice[0] &&
      !showlandDetailsOffice ? (
        <div className="co-contact-card_element">
          <Icon icon="suite_case_outline" color="#ADADAD" />

          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
            className={
              statusValue.includes(typeBasedLandlineOffice[0].status)
                ? "text-secondary"
                : ""
            }
          >
            {parsePhoneNumberFromString(
              `${typeBasedLandlineOffice[0]?.country_code}${typeBasedLandlineOffice[0]?.value}`
            )
              ? parsePhoneNumberFromString(
                  `${typeBasedLandlineOffice[0]?.country_code}${typeBasedLandlineOffice[0]?.value}`
                ).formatInternational()
              : "-"}{" "}
            {_.get(typeBasedLandlineOffice, "", []) &&
            typeBasedLandlineOffice.length > 1 ? (
              <Typography
                type="p"
                subType="regular"
                className="mx-chip"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowlandDetailsOffice(true);
                }}
              >
                +{typeBasedLandlineOffice.length}
              </Typography>
            ) : (
              <></>
            )}{" "}
            {/* {_.get(landline[0], "comment", "") */}
            {commentLandlineExists && (
              <StatefulTooltip
                accessibilityType={"tooltip"}
                content={
                  <div
                    style={{
                      minWidth: "auto",
                      maxWidth: "300px",
                      minHeight: "auto",
                      maxHeight: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 3, // Limits to 3 lines
                      WebkitBoxOrient: "vertical",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {_.get(landline[0], "comment", "")}
                  </div>
                }
              >
                <div>
                  <Icon icon="contact_chat_icon" color="#ADADAD" />
                </div>
              </StatefulTooltip>
            )}
          </Typography>

          <Icon
            icon="landline_outline"
            size={20}
            className={`${
              statusValue.includes(typeBasedLandlineOffice[0].status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedLandlineOffice?.[0]?.call_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
          <Icon
            icon="voice_bot_status"
            size={18}
            className={`${
              statusValue.includes(typeBasedLandlineOffice[0].status)
                ? "mx-icon--path-fill-disabled"
                : typeBasedLandlineOffice?.[0]?.ac_sts === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
        </div>
      ) : (
        <>
          {Array.isArray(typeBasedLandlineOffice) &&
            typeBasedLandlineOffice.length > 0 &&
            typeBasedLandlineOffice.map((item) => {
              let ValidLandNumberFormats = parsePhoneNumberFromString(
                `${item?.country_code}${item?.value}`
              );
              let formattedLandNumbers =
                ValidLandNumberFormats &&
                ValidLandNumberFormats?.formatInternational();

              return (
                <div className="co-contact-card_element">
                  <Icon icon="suite_case_outline" color="#ADADAD" />

                  <Typography
                    type="p"
                    subType="regular"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                    className={
                      statusValue.includes(item.status) ? "text-secondary" : ""
                    }
                  >
                    {formattedLandNumbers
                      ? formatNumber(formattedLandNumbers)
                      : "-"}{" "}
                  </Typography>

                  {item.call_status && (
                    <Icon
                      icon="landline_outline"
                      size={20}
                      className={`${
                        statusValue.includes(item.status)
                          ? "mx-icon--path-fill-disabled"
                          : item.call_status === "VALID"
                          ? "mx-icon--path-fill-success"
                          : "mx-icon--path-fill-error"
                      }`}
                    />
                  )}
                  {item.ac_sts && (
                    <Icon
                      icon="voice_bot_status"
                      size={18}
                      className={`${
                        statusValue.includes(item.status)
                          ? "mx-icon--path-fill-disabled"
                          : item.ac_sts === "VALID"
                          ? "mx-icon--path-fill-success"
                          : "mx-icon--path-fill-error"
                      }`}
                    />
                  )}
                  {item?.comment && (
                    <StatefulTooltip
                      accessibilityType={"tooltip"}
                      content={
                        <div
                          style={{
                            minWidth: "auto",
                            maxWidth: "300px",
                            minHeight: "auto",
                            maxHeight: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3, // Limits to 3 lines
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {_.get(item, "comment", "")}
                        </div>
                      }
                    >
                      <div>
                        <Icon icon="contact_chat_icon" color="#ADADAD" />
                      </div>
                    </StatefulTooltip>
                  )}
                </div>
              );
            })}
        </>
      )}

      {validEmail && validEmail[0] && !showEmailDetails ? (
        <div className="co-contact-card_element">
          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
            className={
              statusValue.includes(validEmail[0].status) ? "text-secondary" : ""
            }
          >
            {validEmail?.[0]?.value}{" "}
            {validEmail.length > 1 ? (
              <Typography
                type="p"
                subType="regular"
                className="mx-chip"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowEmailDetails(true);
                }}
              >
                +{validEmail.length}
              </Typography>
            ) : (
              <></>
            )}{" "}
            {/* {_.get(email[0], "comment", "")  */}
            {commentEmailExists && (
              <StatefulTooltip
                accessibilityType={"tooltip"}
                content={
                  <div
                    style={{
                      minWidth: "auto",
                      maxWidth: "300px",
                      minHeight: "auto",
                      maxHeight: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 3, // Limits to 3 lines
                      WebkitBoxOrient: "vertical",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {_.get(email[0], "comment", "")}
                  </div>
                }
              >
                <div>
                  <Icon icon="contact_chat_icon" color="#ADADAD" />
                </div>
              </StatefulTooltip>
            )}{" "}
          </Typography>

          <Icon
            icon="email_status"
            size={18}
            className={`${
              statusValue.includes(validEmail[0].status)
                ? "mx-icon--path-fill-disabled"
                : validEmail[0].email_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
        </div>
      ) : (
        <>
          {Array.isArray(validEmail) &&
            validEmail.length > 0 &&
            validEmail.map((item) => {
              return (
                <div className="co-contact-card_element">
                  <Typography
                    type="p"
                    subType="regular"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                    className={
                      statusValue.includes(item.status) ? "text-secondary" : ""
                    }
                  >
                    {_.get(item, "value", "-")}
                  </Typography>

                  {item.email_status && (
                    <Icon
                      icon="email_status"
                      size={18}
                      className={`${
                        statusValue.includes(item.status)
                          ? "mx-icon--path-fill-disabled"
                          : item.email_status === "VALID"
                          ? "mx-icon--path-fill-success"
                          : "mx-icon--path-fill-error"
                      }`}
                    />
                  )}
                  {item?.comment && (
                    <StatefulTooltip
                      accessibilityType={"tooltip"}
                      content={
                        <div
                          style={{
                            minWidth: "auto",
                            maxWidth: "300px",
                            minHeight: "auto",
                            maxHeight: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3, // Limits to 3 lines
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {_.get(item, "comment", "")}
                        </div>
                      }
                    >
                      <div>
                        <Icon icon="contact_chat_icon" color="#ADADAD" />
                      </div>
                    </StatefulTooltip>
                  )}
                </div>
              );
            })}
        </>
      )}

      {validAddress && validAddress[0] && !showAddressDetails ? (
        <div className="co-contact-card_element">
          <Typography
            type="p"
            subType="regular"
            style={{ display: "flex", alignItems: "center", gap: "2px" }}
            className={
              statusValue.includes(validAddress[0].status)
                ? "text-secondary"
                : ""
            }
          >
            {validAddress?.[0]?.line_1} {validAddress?.[0]?.line_2} <br />
            {validAddress?.[0]?.city}{" "}
            {format.rd({
              id: _.get(validAddress, "[0]state", ""),
              name: "state_list",
            })}{" "}
            {_.get(validAddress, "[0]country", "")}{" "}
            {validAddress?.[0]?.zip_code}{" "}
            {validAddress.length > 1 ? (
              <Typography
                type="p"
                subType="regular"
                className="mx-chip"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowAddressDetails(true);
                }}
              >
                +{validAddress.length}
              </Typography>
            ) : (
              <></>
            )}{" "}
            {/* {_.get(address[0], "comment", "") */}
            {commentAddressExists && (
              <StatefulTooltip
                accessibilityType={"tooltip"}
                content={
                  <div
                    style={{
                      minWidth: "auto",
                      maxWidth: "300px",
                      minHeight: "auto",
                      maxHeight: "200px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 3, // Limits to 3 lines
                      WebkitBoxOrient: "vertical",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {_.get(address[0], "comment", "")}
                  </div>
                }
              >
                <div>
                  <Icon icon="contact_chat_icon" color="#ADADAD" />
                </div>
              </StatefulTooltip>
            )}{" "}
          </Typography>

          <Icon
            icon="address_status"
            size={18}
            className={`${
              statusValue.includes(validAddress[0].status)
                ? "mx-icon--path-fill-disabled"
                : validAddress[0].address_status === "VALID"
                ? "mx-icon--path-fill-success"
                : "mx-icon--path-fill-error"
            }`}
          />
        </div>
      ) : (
        <>
          {Array.isArray(validAddress) &&
            validAddress.length > 0 &&
            validAddress.map((item) => {
              return (
                <div className="co-contact-card_element">
                  <Typography
                    type="p"
                    subType="regular"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                    className={
                      statusValue.includes(item.status) ? "text-secondary" : ""
                    }
                  >
                    {item?.line_1} {item?.line_2} <br />
                    {item?.city}{" "}
                    {format.rd({
                      id: _.get(item, "state", ""),
                      name: "state_list",
                    })}{" "}
                    {_.get(item, "country", "")} {item?.zip_code}
                  </Typography>

                  {item.address_status && (
                    <Icon
                      icon="address_status"
                      size={18}
                      className={`${
                        statusValue.includes(item.status)
                          ? "mx-icon--path-fill-disabled"
                          : item.address_status === "VALID"
                          ? "mx-icon--path-fill-success"
                          : "mx-icon--path-fill-error"
                      }`}
                    />
                  )}
                  {item?.comment && (
                    <StatefulTooltip
                      accessibilityType={"tooltip"}
                      content={
                        <div
                          style={{
                            minWidth: "auto",
                            maxWidth: "300px",
                            minHeight: "auto",
                            maxHeight: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3, // Limits to 3 lines
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {_.get(item, "comment", "")}
                        </div>
                      }
                    >
                      <div>
                        <Icon icon="contact_chat_icon" color="#ADADAD" />
                      </div>
                    </StatefulTooltip>
                  )}
                </div>
              );
            })}
        </>
      )}
      <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
        {custom_field && custom_field.ssn ? (
          <Typography type="p" subType="regular" style={{ width: "48%" }}>
            SSN:{" "}
            {formattedSSN ? formattedSSN : custom_field && custom_field?.ssn}
          </Typography>
        ) : (
          <></>
        )}
        {custom_field && custom_field.dob ? (
          <Typography type="p" subType="regular" style={{ width: "48%" }}>
            DOB:{" "}
            {format.date({
              isOrg: true,
              value: custom_field && custom_field.dob ? custom_field.dob : null,
            })}
          </Typography>
        ) : (
          <></>
        )}
        {custom_field && custom_field.dl_state ? (
          <Typography type="p" subType="regular" style={{ width: "48%" }}>
            DL State:{" "}
            {custom_field && custom_field.dl_state
              ? custom_field.dl_state
              : "-"}
          </Typography>
        ) : (
          <></>
        )}
        {custom_field && custom_field.dl_number ? (
          <Typography type="p" subType="regular" style={{ width: "48%" }}>
            DL Number:{" "}
            {custom_field && custom_field.dl_number
              ? custom_field.dl_number
              : "-"}
          </Typography>
        ) : (
          <></>
        )}
      </div>

      <div className="add_contact_action_wraper">
        <div></div>
        {_.get(customerData, "data.doc.contacts", []).length > 0 ? (
          <div className="add_contact_icon_container">
            <RBACWrapper role={EDIT_CONTACTS} type="PERMISSION">
              <Icon
                icon="edit"
                size={16}
                color="#adadad"
                isPressable
                onClick={() => setDrawer(EDIT_CONTACT, contact)}
              />
            </RBACWrapper>
            <RBACWrapper role={DELETE_CONTACTS} type="PERMISSION">
              <Icon
                icon="delete"
                size={16}
                color="#adadad"
                isPressable
                onClick={() => {
                  handleModal({
                    title: "",
                    content: "Are you sure, you want to delete this Contact?",
                    successCallback: () => {
                      mutateAsync(
                        {
                          customerId: customerId,
                          organization: currentOrganization,
                          contactId: contact?._id,
                        },
                        {
                          onError: (error) => {
                            if (error.response) {
                              let { data } = error.response;
                              toast.error(data.message);
                            }
                          },
                          onSuccess: (data) => {
                            queryClient.invalidateQueries({
                              queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
                            });
                          },
                        }
                      );
                    },
                    formContent: () => {
                      return <></>;
                    },
                    cancelCallback: () => {},
                    isChildren: false,
                    image: Warning,
                    successButtonType: "error",
                    buttonText: "Delete",
                    closeButtonText: "Cancel",
                    isCloseAble: false,
                  });
                }}
              />
            </RBACWrapper>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const LocalTime = () => {
  const { customerId } = useParams();
  const [time, setTime] = useState(moment().format("hh:mm:ss a"));

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  useEffect(() => {
    const timerID = setInterval(
      () =>
        setTime(
          moment
            .tz(_.get(customerData, "data.doc.timezone", ""))
            .format("hh:mm:ss A")
        ),
      1000
    );

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  return (
    <>
      <Icon icon="clock" size={18} />
      <Typography type="p" subType="regular">
        {time} <span className="text-secondary">(Local Time)</span>
      </Typography>
    </>
  );
};

export default function COContacts({ contacts = [], setDrawer = () => {} }) {
  const getContact = (contactsItem) => {
    // Filter contacts to get primary contacts
    const primaryContacts = contactsItem.filter(
      (contactfi) => contactfi?.designation === "primary_contact"
    );

    // Return the first primary contact if available, otherwise return the first contact in the list
    return primaryContacts?.length > 0 ? primaryContacts[0] : contacts[0] || {};
  };
  const [contact, setContact] = useState([]);

  useEffect(() => {
    if (getContact(contacts)) {
      setContact([
        {
          id: getContact(contacts)?._id,
          ...getContact(contacts),
        },
      ]);
    }
  }, [contacts, contacts.length]);

  const { mutateAsync } = useDeleteCustomerContact();

  return (
    <div className="co-contact-card_wrapper">
      <SelectBox
        size="mini"
        placeholder="No Contacts"
        clearable={false}
        value={contact}
        onChange={(e) => {
          setContact(e.value);
        }}
        options={
          contacts && Array.isArray(contacts)
            ? contacts.map((con) => {
                return {
                  label: `${con.first_name ? con.first_name : ""} ${
                    con.last_name ? con.last_name : ""
                  } ${con.relation ? `| ${con.relation}` : ""} | ${
                    con.designation && con.designation === "primary_contact"
                      ? "PRIMARY"
                      : con.designation &&
                        con.designation === "escalation_contact"
                      ? "ESCALATION"
                      : "SECONDARY"
                  }`,
                  id: con._id,
                  ...con,
                };
              })
            : []
        }
      />

      <div className="co-contact-card_container">
        <ContactDetails
          contact={contact && contact[0] ? contact[0] : {}}
          setDrawer={setDrawer}
          mutateAsync={mutateAsync}
        />
        <div className="co-contact-card_footer">
          <div className="co-contact-card-item">
            <LocalTime />
          </div>
          <RBACWrapper role={ADD_CONTACTS} type="PERMISSION">
            <div
              className="co-contact-card_button"
              onClick={() => setDrawer(BULK_PHONE_IMPORT, contact)}
            >
              <Typography type="p" subType="regular">
                Import
              </Typography>
            </div>
          </RBACWrapper>
          <RBACWrapper role={ADD_CONTACTS} type="PERMISSION">
            <div
              className="co-contact-card_button"
              onClick={() => setDrawer(ADD_CONTACT_DRAWER)}
            >
              <Typography type="p" subType="regular">
                + Contact
              </Typography>
            </div>
          </RBACWrapper>
        </div>
      </div>
    </div>
  );
}
