import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { ParagraphLarge } from "baseui/typography";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { SelectBox } from "../components/SelectBox";
import { TextArea } from "../components/TextArea";
import { MaxyfiContext } from "../providers/MaxyfiProvider";
import { ADD_LIST_VALUES } from "../providers/RBACProvider/permissionList";
import {
  CUSTOMER_OVERVIEW,
  CUSTOMER_OVERVIEW_,
  CO_TIMELINE_,
  CO_INVOICES_,
} from "../constants";
import setSelectValues from "../utils/setSelectValues";
import getSelectValues from "../utils/getSelectValues";
import { KIND, SIZE, TextButton } from "../components/TextButton";
import { putCustomerStatus } from "../services";
import _ from "lodash";
import ActionReminders from "./ActionReminders";

const CustomerStatus = ({ handleModal, customerId, ...rest }) => {
  const queryClient = useQueryClient();
  let maxyfiContextValue = useContext(MaxyfiContext);
  let {
    currentOrganization,
    referenceData,
    space,
    refetch,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;
  const intl = useIntl();

  const customerDetails = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      customer_status: [],
    },
  });

  let { customer_status } = watch();

  const putCustomerStatusService = useMutation(
    (data) =>
      putCustomerStatus({
        organization: currentOrganization,
        customerId: customerId,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        if (
          customer_status &&
          customer_status[0] &&
          customer_status[0]?.isCreatable
        ) {
          refetch({
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        handleModal();
        queryClient.invalidateQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        queryClient.invalidateQueries(`${CO_INVOICES_}${customerId}`);

        queryClient.invalidateQueries(`${CO_TIMELINE_}${customerId}`);
      },
    }
  );

  const onSubmit = async (data) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      customer_status: getSelectValues(data.customer_status),
      customer_comment: data.comment,
      mention_users: uniqueMentionedUsers,
    };

    await putCustomerStatusService.mutateAsync(values);
  };

  useEffect(() => {
    if ((customerDetails && customerDetails.data, customerDetails.data.doc)) {
      let customerInfo = _.get(customerDetails, "data.doc");
      let customerStatusList = referenceData["customer_status_list"];
      let customerStatus = customerInfo?.customer_status?.split("(");
      let customerTwoStatus = customerStatus && customerStatus[1]?.split(")");
      let toLower;
      if (customerTwoStatus) {
        toLower =
          customerTwoStatus &&
          customerTwoStatus[0] &&
          customerTwoStatus[0]?.toLowerCase();
      }

      if (
        customerTwoStatus &&
        customerTwoStatus[0] &&
        customerTwoStatus[0]?.toLowerCase() === "auto"
      ) {
        let rdAutoValue = customerStatusList?.filter((e) => e?.id === toLower);

        setValue("customer_status", rdAutoValue);
      } else {
        setValue("customer_status", [
          { id: rest.customerStatus, label: rest.customerStatus },
        ]);
      }

      setValue("comment", _.get(customerInfo, "customer_comment", ""));
    }
  }, [customerDetails.isLoading]);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  let filterData = currentDefaultFormatDetails?.csm?.filter(
    (e) => e.enb === true
  );

  return (
    <>
      <div style={{ width: "" }}>
        <form
          style={{
            textAlign: "center",
            marginTop: "15px",
            padding: "10px 20px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ParagraphLarge $style={{ marginBottom: "20px" }}>
            Select the Consumer Status
          </ParagraphLarge>
          <Controller
            name="customer_status"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                creatable={creatablePermission ? true : false}
                clearable={false}
                // disabled={isDisabled}
                requiredAstric={true}
                //   onInputChange={(evt) => {
                // fetchWorkflow({ name: evt.target.value });
                //   }}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "customer_status",
                })}
                placeholder={intl.formatMessage({
                  id: "customer_status",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={filterData
                  ?.map((e) => {
                    return {
                      id: e?.nam,
                      label: e?.nam,
                    };
                  })
                  .sort((a, b) => a?.id?.localeCompare(b?.id))}
                //   options={workflowList.map((e) => ({
                //     id: e._id,
                //     label: e.name,
                //   }))}.sort((a, b) => a?.id.localeCompare(b?.id)
              />
            )}
          />

          <ParagraphLarge $style={{ marginBottom: "20px" }}>
            Consumer Comments
          </ParagraphLarge>
          <ActionReminders
            watch={watch}
            control={control}
            errors={errors}
            setValue={setValue}
            is_calendar={false}
            is_incrementor={false}
            is_hold={false}
            is_followup={false}
            is_confirmation={false}
            is_valid_from_contact={false}
          />

          <div
            style={{
              marginTop: "15px",
              textAlign: "center",
              // marginBottom: "25px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "10px 20px",
            }}
          >
            <div style={{ height: "", width: "96px" }}>
              <TextButton
                type="button"
                fullWidth
                size={SIZE.compact}
                kind={KIND.tertiary}
                // onClick={close}
                onClick={() => {
                  handleModal();
                  reset();
                }}
              >
                Cancel
              </TextButton>
            </div>
            <div style={{ height: "", width: "96px" }}>
              <TextButton
                type="submit"
                fullWidth
                size={SIZE.compact}
                kind={KIND.primary}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Apply
              </TextButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CustomerStatus;
