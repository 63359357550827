import React, { useContext, useState, useEffect } from "react";
import {
  HeadingXSmall,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  ParagraphMedium,
} from "baseui/typography";
import { Modal, SIZE } from "baseui/modal";
import moment from "moment";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { FormattedTime } from "react-intl";

import { Icon, Typography } from "../../components_v2";
import { KIND, SIZE as size } from "../../components/IconButton";
import { TextButton } from "../../components/TextButton";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import queryClient from "../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../constants";
import useFormat from "../../hooks/useFormat";
import { useSelector } from "react-redux";

const stateInit = {
  weeklyCounterAlert: false,
  weeklyCounterAlertType: "error",
  monthlyCounterAlert: false,
  monthlyCounterAlertType: "error",
  callAttemptsAlert: false,
  callAttemptsAlertType: "error",
  rpcAlert: false,
  rpcAlertType: "error",
  timezoneStartTimeAlert: false,
  timezoneStartTimeAlertDiff: { hour: 0, minute: 0 },
  timezoneEndTimeAlert: false,
  timezoneEndTimeAlertDiff: { hour: 0, minute: 0 },
  customerTime: new Date(),
  hasAgentInstruction: false,
  agentInstruction: "",
  breachType: "",
  hasClientInstruction: false,
  clientInstruction: "",
};

function AgentInstruction({
  isLoading,
  active,
  setIsActive,
  isClientInstruction,
  isEnable,
  setIsEnable,
  isAgentData,
  setIsAgentData,
}) {
  const [state, setState] = useState({ ...stateInit });
  const format = useFormat();
  const { customerId } = useParams();
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);
  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  console.log("TIMEEEEEEE", state, isEnable);

  let { validationField } = useSelector((e) => e.customerOverviewDrawer);

  console.log("CLIENT_INS", validationField);

  const isDisplayOnLoad = _.get(validationField, "isDisplayOnLoad");
  const isDisplay = isDisplayOnLoad !== undefined ? isDisplayOnLoad : true;
  useEffect(() => {
    let alertInfo = {
      ...stateInit,
    };

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.mini_miranda
    ) {
      if (
        customerData.data.doc.mini_miranda.agent_instruction &&
        customerData.data.doc.mini_miranda.agent_instruction.length > 0
      ) {
        if (isEnable.isType === "BULB_ENABLE") {
          alertInfo.hasAgentInstruction = true;
          alertInfo.agentInstruction =
            customerData.data.doc.mini_miranda.agent_instruction;
        } else {
          alertInfo.hasAgentInstruction = false;
          alertInfo.agentInstruction = "";
        }
      }

      if (
        validationField &&
        validationField.agentInstruction &&
        validationField.agentInstruction[0] &&
        validationField.agentInstruction[0].instruction &&
        validationField.agentInstruction[0].instruction.length > 0
      ) {
        if (isEnable.isClientType === "BULB_ENABLE") {
          alertInfo.hasClientInstruction = true;
          alertInfo.clientInstruction =
            validationField.agentInstruction[0].instruction;
        } else {
          alertInfo.hasClientInstruction = false;
          alertInfo.clientInstruction = "";
        }
      }

      const weeklyCount = _.get(customerData, "data.doc.weekly_comm_count");
      const weeklyMaxCount = _.get(
        customerData,
        "data.doc.max_weekly_follow_up"
      );

      console.log(
        "POP_UP_8",
        weeklyCount >= weeklyMaxCount,
        weeklyCount,
        weeklyMaxCount
      );

      if (weeklyCount >= weeklyMaxCount) {
        alertInfo.weeklyCounterAlert = true;

        if (weeklyCount === weeklyMaxCount) {
          alertInfo.weeklyCounterAlertType = "info";
        }
      }

      const monthlyCount = _.get(customerData, "data.doc.monthly_comm_count");
      const monthlyMaxCount = _.get(
        customerData,
        "data.doc.max_monthly_follow_up"
      );

      if (monthlyCount >= monthlyMaxCount) {
        alertInfo.monthlyCounterAlert = true;
        if (monthlyCount === monthlyMaxCount) {
          alertInfo.monthlyCounterAlertType = "info";
        }
      }

      const callAttempt = _.get(customerData, "data.doc.call_attempt_count");
      const callMaxAttempt = _.get(
        customerData,
        "data.doc.mini_miranda.max_call_attempts"
      )
        ? _.get(customerData, "data.doc.mini_miranda.max_call_attempts")
        : undefined;

      console.log(
        "ALERT_12",
        Number(callAttempt) >= Number(callMaxAttempt),
        callAttempt,
        callMaxAttempt
      );

      if (Number(callAttempt) >= Number(callMaxAttempt)) {
        alertInfo.callAttemptsAlert = true;

        if (Number(callAttempt) === Number(callMaxAttempt)) {
          alertInfo.callAttemptsAlertType = "info";
        }
      }

      const callRPC = _.get(customerData, "data.doc.call_rpc_count");
      const callMaxRPC = _.get(
        customerData,
        "data.doc.mini_miranda.max_call_rpc"
      )
        ? _.get(customerData, "data.doc.mini_miranda.max_call_rpc")
        : undefined;

      console.log("ALERT_13", callRPC >= callMaxRPC, callRPC, callMaxRPC);

      if (Number(callRPC) >= Number(callMaxRPC)) {
        alertInfo.rpcAlert = true;

        if (Number(callRPC) === Number(callMaxRPC)) {
          alertInfo.rpcAlertType = "info";
        }
      }

      const customerTimezone = _.get(customerData, "data.doc.timezone");
      const followupStartTime = _.get(
        currentDefaultFormatDetails,
        "mini_miranda_settings.follow_up_start_time",
        ""
      ).split("_");
      const followupEndTime = _.get(
        currentDefaultFormatDetails,
        "mini_miranda_settings.follow_up_end_time",
        ""
      ).split("_");

      if (
        customerTimezone &&
        customerTimezone.length > 0 &&
        followupStartTime.every((e) => e) &&
        followupEndTime.every((e) => e)
      ) {
        const startTimeDiff = moment.tz(customerTimezone).diff(
          moment.tz(customerTimezone).set({
            hour:
              followupStartTime[2] === "AM"
                ? followupStartTime[0]
                : Number(followupStartTime[0]) + 12,
            minute: followupStartTime[1],
            second: 0,
            millisecond: 0,
          }),
          "minutes"
        );

        const endTimeDiff = moment.tz(customerTimezone).diff(
          moment.tz(customerTimezone).set({
            hour:
              followupEndTime[2] === "AM"
                ? followupEndTime[0]
                : Number(followupEndTime[0]) + 12,
            minute: followupEndTime[1],
            second: 0,
            millisecond: 0,
          }),
          "minutes"
        );

        const customerTime = moment.tz(customerTimezone).toDate();

        if (startTimeDiff < 0) {
          const hour = Math.trunc(Math.abs(Number(startTimeDiff / 60)));
          const minute = Math.trunc(Math.abs(((startTimeDiff / 60) % 1) * 60));

          alertInfo.timezoneStartTimeAlert = true;
          alertInfo.timezoneStartTimeAlertDiff = { hour, minute };
          alertInfo.customerTime = customerTime;
        }

        if (endTimeDiff > 0) {
          const hour = Math.trunc(Math.abs(Number(endTimeDiff / 60)));
          const minute = Math.trunc(Math.abs(((endTimeDiff / 60) % 1) * 60));

          alertInfo.timezoneEndTimeAlert = true;
          alertInfo.timezoneEndTimeAlertDiff = { hour, minute };
          alertInfo.customerTime = customerTime;
        }
      }

      setIsAgentData({ ...isAgentData, ...alertInfo });
      if (
        _.get(validationField, "agentInstruction", []) &&
        _.get(validationField, "agentInstruction", []).length > 0 &&
        isDisplay
      ) {
        console.log("POP_UP_1");
        setIsActive(true);
      }

      if (!state.hasAgentInstruction && !isDisplay) {
        console.log("POP_UP_2");
        setIsActive(false);
      } else if (isClientInstruction) {
        console.log("POP_UP_3");
        setIsActive(true);
      }

      if (
        alertInfo.weeklyCounterAlert ||
        alertInfo.monthlyCounterAlert ||
        alertInfo.callAttemptsAlert ||
        alertInfo.rpcAlert ||
        alertInfo.timezoneStartTimeAlert ||
        alertInfo.timezoneEndTimeAlert ||
        alertInfo.hasAgentInstruction ||
        alertInfo.hasClientInstruction
      ) {
        console.log("POP_UP_4", alertInfo);
        setState({ ...alertInfo });
        // if (
        //   _.get(customerData, "data.doc.max_call_attempts", 0) ||
        //   _.get(customerData, "data.doc.max_monthly_follow_up", 0)
        // ) {
        //   setIsActive(false);
        // } else {
        //   setIsActive(true);
        // }
        setIsActive(true);
      } else {
        console.log("POP_UP_5");
        setIsActive(false);
      }
    } else if (isClientInstruction) {
      console.log("POP_UP_6");
      setIsActive(true);
    } else {
      console.log("POP_UP_7");
      setIsActive(false);
    }
  }, [isEnable.isCount]);

  return (
    <>
      <Modal
        isOpen={active}
        animate
        autoFocus={false}
        size={SIZE.auto}
        overrides={{
          Close: {
            style: () => ({
              display: "none",
            }),
          },
          Dialog: {
            style: () => ({
              // borderRadius: "5px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              padding: "5px",
              borderTop: "5px solid red", //TODO: Based on whether it has error or info or none
            }),
          },
        }}
      >
        <div
          className={`agent_instruction_container ${
            state.hasAgentInstruction || isDisplay
              ? "agent_instruction_container--no_instruction"
              : ""
          }`}
        >
          {state.hasAgentInstruction ||
          (_.get(validationField, "agentInstruction", []).length > 0 &&
            isClientInstruction) ? (
            <div>
              {state.weeklyCounterAlert ||
              state.monthlyCounterAlert ||
              state.callAttemptsAlert ||
              state.rpcAlert ||
              state.timezoneStartTimeAlert ||
              state.timezoneEndTimeAlert ||
              _.get(validationField, "agentInstruction", []).length > 0 ? (
                <div className="agent_instruction_header">
                  {state.weeklyCounterAlert &&
                  _.get(customerData, "data.doc.max_weekly_follow_up") ? (
                    <div className="agent_instruction_alert">
                      <Icon
                        icon="alert"
                        size={18}
                        className={`mx-icon--path-fill-${state.weeklyCounterAlertType}`}
                      />
                      <Typography className="text-secondary">
                        Weekly #:{" "}
                        <span
                          className={`text-${state.weeklyCounterAlertType}`}
                        >
                          {_.get(customerData, "data.doc.weekly_comm_count")}/
                          {_.get(customerData, "data.doc.max_weekly_follow_up")}
                        </span>
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}
                  {state.monthlyCounterAlert &&
                  _.get(customerData, "data.doc.max_monthly_follow_up") ? (
                    <div className="agent_instruction_alert">
                      <Icon
                        icon="alert"
                        size={18}
                        className={`mx-icon--path-fill-${state.monthlyCounterAlertType}`}
                      />
                      <Typography className="text-secondary">
                        Monthly #:{" "}
                        <span
                          className={`text-${state.monthlyCounterAlertType}`}
                        >
                          {_.get(customerData, "data.doc.monthly_comm_count")}/
                          {_.get(
                            customerData,
                            "data.doc.max_monthly_follow_up"
                          )}
                        </span>
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}
                  {state.callAttemptsAlert &&
                  _.get(customerData, "data.doc.max_call_attempts") ? (
                    <div className="agent_instruction_alert">
                      <Icon
                        icon="alert"
                        size={18}
                        className={`mx-icon--path-fill-${state.callAttemptsAlertType}`}
                      />
                      <Typography className="text-secondary">
                        Call Attempt #:{" "}
                        <span className={`text-${state.callAttemptsAlertType}`}>
                          {_.get(customerData, "data.doc.call_attempt_count")}/
                          {_.get(customerData, "data.doc.max_call_attempts")}
                        </span>
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}
                  {state.rpcAlert &&
                  _.get(customerData, "data.doc.max_call_rpc") ? (
                    <div className="agent_instruction_alert">
                      <Icon
                        icon="alert"
                        size={18}
                        className={`mx-icon--path-fill-${state.rpcAlertType}`}
                      />
                      <Typography className="text-secondary">
                        Call Right Party Contact #:{" "}
                        <span className={`text-${state.rpcAlertType}`}>
                          {_.get(customerData, "data.doc.call_rpc_count")}/
                          {_.get(customerData, "data.doc.max_call_rpc")}
                        </span>
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}

                  {state.timezoneStartTimeAlert ? (
                    <div className="agent_instruction_alert">
                      <Icon
                        icon="alert_clock"
                        size={18}
                        className={`mx-icon--path-fill-error`}
                      />
                      <Typography>
                        {
                          moment
                            .tz(_.get(customerData, "data.doc.timezone", ""))
                            .format("hh:mm A")
                          //     moment
                          // .tz(
                          //   _.get(
                          //     state.customerTime,
                          //     customerData,
                          //     "data.doc.timezone",
                          //     ""
                          //   )
                          // )
                          //       .format("hh:mm A")
                        }{" "}
                        <span className="text-secondary mx-p--regular">
                          (
                          {state.timezoneStartTimeAlertDiff.hour > 0
                            ? `${state.timezoneStartTimeAlertDiff.hour} hrs`
                            : ""}{" "}
                          {state.timezoneStartTimeAlertDiff.minute > 0
                            ? `${state.timezoneStartTimeAlertDiff.minute} mins`
                            : ""}{" "}
                          before Start Time)
                        </span>
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}

                  {state.timezoneEndTimeAlert ? (
                    <div className="agent_instruction_alert">
                      <Icon
                        icon="alert_clock"
                        size={18}
                        className={`mx-icon--path-fill-error`}
                      />
                      <Typography>
                        {
                          moment
                            .tz(_.get(customerData, "data.doc.timezone", ""))
                            .format("hh:mm A")
                          //     moment
                          // .tz(
                          //   _.get(
                          //     state.customerTime,
                          //     customerData,
                          //     "data.doc.timezone",
                          //     ""
                          //   )
                          // )
                          //       .format("hh:mm A")
                        }{" "}
                        <span className="text-secondary mx-p--regular">
                          (
                          {state.timezoneEndTimeAlertDiff.hour > 0
                            ? `${state.timezoneEndTimeAlertDiff.hour} hrs`
                            : ""}{" "}
                          {state.timezoneEndTimeAlertDiff.minute > 0
                            ? `${state.timezoneEndTimeAlertDiff.minute} mins`
                            : ""}{" "}
                          Past End Time)
                        </span>
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              {_.get(
                customerData,
                "data.doc.mini_miranda.agent_instruction",
                ""
              ) &&
              _.get(validationField, "agentInstruction", []).length > 0 &&
              isClientInstruction &&
              active ? (
                //If the both condition true minimrandea and client information

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #EEEEEE",
                    justifyContent: "space-between",
                  }}
                >
                  {state.agentInstruction ? (
                    <>
                      <div className="mini_instruction_both">
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Icon icon="light_bulb" size={22} />
                          <Typography type="h2">
                            Mini Miranda Configuration
                          </Typography>
                        </div>
                        <div className="agent_instruction_content">
                          <Typography subType="regular">
                            {state.agentInstruction}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          height: "100%",
                          width: "2px",
                        }}
                      ></div>
                    </>
                  ) : (
                    <></>
                  )}

                  {_.get(validationField, "agentInstruction", []) &&
                    isClientInstruction &&
                    _.get(validationField, "agentInstruction", []).length > 0 &&
                    _.get(validationField, "agentInstruction", []).map((i) => {
                      return (
                        <div
                          className="client_agent_instruction_both"
                          style={
                            state.agentInstruction ? {} : { width: "100%" }
                          }
                        >
                          <div style={{ display: "flex", gap: "10px" }}>
                            <Icon icon="light_bulb" size={22} />
                            <Typography type="h2">
                              {format.rd({
                                id: i?.client,
                                name: "business_unit_list",
                              })}{" "}
                              - Agent Instruction
                            </Typography>
                          </div>

                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: i.instruction,
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : _.get(validationField, "agentInstruction", []) &&
                _.get(validationField, "agentInstruction", []).length > 0 &&
                isClientInstruction &&
                !_.get(
                  customerData,
                  "data.doc.mini_miranda.agent_instruction",
                  ""
                ) ? (
                //only CLient Agent Instructoion
                _.get(validationField, "agentInstruction", []) &&
                _.get(validationField, "agentInstruction", []).length > 0 &&
                isClientInstruction &&
                _.get(validationField, "agentInstruction", []).map((i) => {
                  return (
                    <div style={{ overflowY: "scroll", height: "300px" }}>
                      <div
                        className="agent_instruction_title"
                        style={{
                          overflowY: "scroll",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Icon icon="light_bulb" size={22} />
                        <Typography type="h2">
                          {" "}
                          {format.rd({
                            id: i?.client,
                            name: "business_unit_list",
                          })}{" "}
                          - Agent Instruction
                        </Typography>
                      </div>
                      <div className="agent_instruction_content --client_inst">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: i.instruction,
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                //only minimirando COnfiguration
                <>
                  {_.get(
                    customerData,
                    "data.doc.mini_miranda.agent_instruction",
                    ""
                  ) &&
                    active && (
                      <>
                        <div className="agent_instruction_title">
                          <Icon icon="light_bulb" size={22} />
                          <Typography type="h2">
                            Mini Miranda - Agent Instruction
                          </Typography>
                        </div>
                        <div className="agent_instruction_content">
                          <Typography subType="regular">
                            {state.agentInstruction}
                          </Typography>
                        </div>
                      </>
                    )}
                </>
              )}
            </div>
          ) : (
            <>
              {(state.weeklyCounterAlert ||
                state.monthlyCounterAlert ||
                state.callAttemptsAlert ||
                state.rpcAlert ||
                state.timezoneStartTimeAlert ||
                state.timezoneEndTimeAlert) &&
              !isDisplay ? (
                <>
                  <div className="agent_instruction_title agent_instruction_title--no_instruction">
                    <Typography type="h3" className="text-error">
                      Alert!
                    </Typography>
                  </div>
                  {state.weeklyCounterAlert &&
                  _.get(customerData, "data.doc.max_weekly_follow_up", 0) >
                    0 ? (
                    <div className="agent_instruction_alert agent_instruction_alert--no_instruction">
                      <Icon
                        icon="alert"
                        size={24}
                        className={`mx-icon--path-fill-${state.weeklyCounterAlertType}`}
                      />
                      <div className="agent_instruction_alert_info">
                        <Typography type="h4">
                          You have exhausted weekly follow-ups!
                        </Typography>
                        <Typography
                          subType="regular"
                          className="text-secondary"
                        >
                          Weekly #:{" "}
                          <span
                            className={`text-${state.weeklyCounterAlertType}`}
                          >
                            {_.get(customerData, "data.doc.weekly_comm_count")}/
                            {_.get(
                              customerData,
                              "data.doc.max_weekly_follow_up",
                              0
                            )}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {state.monthlyCounterAlert &&
                  _.get(customerData, "data.doc.max_monthly_follow_up", 0) >
                    0 ? (
                    <div className="agent_instruction_alert agent_instruction_alert--no_instruction">
                      <Icon
                        icon="alert"
                        size={24}
                        className={`mx-icon--path-fill-${state.monthlyCounterAlertType}`}
                      />
                      <div className="agent_instruction_alert_info">
                        <Typography type="h4">
                          You have exhausted monthly follow-ups!
                        </Typography>
                        <Typography
                          subType="regular"
                          className="text-secondary"
                        >
                          Monthly #:{" "}
                          <span
                            className={`text-${state.monthlyCounterAlertType}`}
                          >
                            {_.get(customerData, "data.doc.monthly_comm_count")}
                            /
                            {_.get(
                              customerData,
                              "data.doc.max_monthly_follow_up",
                              0
                            )}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {state.callAttemptsAlert ? (
                    <div className="agent_instruction_alert agent_instruction_alert--no_instruction">
                      <Icon
                        icon="alert"
                        size={24}
                        className={`mx-icon--path-fill-${state.callAttemptsAlertType}`}
                      />
                      <div className="agent_instruction_alert_info">
                        <Typography type="h4">
                          You have exhausted in window Call Attempts
                        </Typography>
                        <Typography
                          subType="regular"
                          className="text-secondary"
                        >
                          Call Attempts #:{" "}
                          <span
                            className={`text-${state.callAttemptsAlertType}`}
                          >
                            {_.get(customerData, "data.doc.call_attempt_count")}
                            /{_.get(customerData, "data.doc.max_call_attempts")}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {state.rpcAlert ? (
                    <div className="agent_instruction_alert agent_instruction_alert--no_instruction">
                      <Icon
                        icon="alert"
                        size={24}
                        className={`mx-icon--path-fill-${state.rpcAlertType}`}
                      />
                      <div className="agent_instruction_alert_info">
                        <Typography type="h4">
                          You have exhausted in Call Right Party Contact (RPC)
                        </Typography>
                        <Typography
                          subType="regular"
                          className="text-secondary"
                        >
                          Call RPC #:{" "}
                          <span className={`text-${state.rpcAlertType}`}>
                            {_.get(customerData, "data.doc.call_rpc_count")}/
                            {_.get(customerData, "data.doc.max_call_rpc")}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {state.timezoneStartTimeAlert ? (
                    <div className="agent_instruction_alert agent_instruction_alert--no_instruction">
                      <Icon
                        icon="alert_clock"
                        size={24}
                        className="mx-icon--path-fill-error"
                      />
                      <div className="agent_instruction_alert_info">
                        <Typography type="h4" className="text-error">
                          {
                            moment
                              .tz(_.get(customerData, "data.doc.timezone", ""))
                              .format("hh:mm A")
                            //     moment
                            // .tz(
                            //   _.get(
                            //     state.customerTime,
                            //     customerData,
                            //     "data.doc.timezone",
                            //     ""
                            //   )
                            // )
                            //     .format("hh:mm A")
                          }
                        </Typography>
                        <Typography
                          subType="regular"
                          className="text-secondary"
                        >
                          You're{" "}
                          {state.timezoneStartTimeAlertDiff.hour > 0
                            ? `${state.timezoneStartTimeAlertDiff.hour} hrs`
                            : ""}{" "}
                          {state.timezoneStartTimeAlertDiff.minute > 0
                            ? `${state.timezoneStartTimeAlertDiff.minute} mins`
                            : ""}{" "}
                          before Start Time
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {state.timezoneEndTimeAlert ? (
                    <div className="agent_instruction_alert agent_instruction_alert--no_instruction">
                      <Icon
                        icon="alert_clock"
                        size={24}
                        className="mx-icon--path-fill-error"
                      />
                      <div className="agent_instruction_alert_info">
                        <Typography type="h4" className="text-error">
                          {
                            moment
                              .tz(_.get(customerData, "data.doc.timezone", ""))
                              .format("hh:mm A")
                            //     moment
                            // .tz(
                            //   _.get(
                            //     state.customerTime,
                            //     customerData,
                            //     "data.doc.timezone",
                            //     ""
                            //   )
                            // )
                            //       .format("hh:mm A")
                          }
                          {/* <FormattedTime value={state.customerTime} /> */}
                        </Typography>
                        <Typography
                          subType="regular"
                          className="text-secondary"
                        >
                          You're{" "}
                          {state.timezoneEndTimeAlertDiff.hour > 0
                            ? `${state.timezoneEndTimeAlertDiff.hour} hrs`
                            : ""}{" "}
                          {state.timezoneEndTimeAlertDiff.minute > 0
                            ? `${state.timezoneEndTimeAlertDiff.minute} mins`
                            : ""}{" "}
                          Past End Time
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <div
            className="agent_instruction_footer"
            style={{ marginTop: "10px" }}
          >
            <TextButton
              kind="secondary"
              size="mini"
              onClick={() => {
                setIsActive(false);
              }}
            >
              Close
            </TextButton>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AgentInstruction;
